&.sticky {
  margin-bottom: rem-calc(map-get($navbar-heights, xs));

  @include media-breakpoint-up(md) {
    // margin-bottom: rem-calc(map-get($navbar-heights, md));
  }

  .nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    .navbar {
      height: rem-calc(map-get($navbar-heights, xs));
      margin-bottom: 0;

      &__brand {
        a,
        img {
          max-width: rem-calc(95);
        }
      }
    }

    .menu--root {
      @include media-breakpoint-up(sm) {
        margin-left: $spacer * -1;
      }
    }

    &--horizontal {
      @include media-breakpoint-up(md) {
        top: rem-calc(map-get($navbar-heights, xs));
      }

      @include media-breakpoint-up(lg) {
        height: 0;

        .menu--horizontal {
          display: none;
        }

        &.show {
          height: rem-calc(39);

          .menu--horizontal {
            display: block;
          }
        }
      }
    }
  }
}
