.breadcrumb {
  padding: 0;
  margin: 0;
  list-style-type: none;

  &-item {
    display: inline-block;

    &:not(:last-child) {
      &::after {
        content: '●';
        font-size: rem-calc(10);
        line-height: rem-calc(19);
        display: inline-block;
        margin: 0 rem-calc(2);
      }
    }

    a {
      font-size: rem-calc(10);
      line-height: rem-calc(19);
      letter-spacing: rem-calc(1);
    }
  }
}
