@import 'select2';

// custom overrides
.select2-container {
  @include font-size($custom-select-font-size);

  .form-control + & {
    width: 100% !important;
  }

  .select2 {
    &-selection {
      &--single {
        height: $custom-select2-height;
        border: $custom-select-border-width solid $custom-select-border-color;
        width: 100%;
        background: $custom-select-background;
        padding-left: $custom-select-padding-x;
        padding-right: $custom-select-padding-x;

        @include border-radius($custom-select-border-radius, 0);

        &:focus {
          outline: none;
          border-color: $custom-select-focus-border-color;
        }

        .select2-selection__rendered {
          color: $custom-select-color;
          line-height: $custom-select2-height;
          padding-left: 0;
        }

        .select2-selection__arrow {
          display: none !important;
        }
      }
    }

    &-dropdown {
      border: $custom-select-border-width solid $custom-select-border-color;
      border-top: none;
      z-index: 2;

      @include border-radius($custom-select-border-radius, 0);
    }

    &-results {
      &__options {
        @include custom-scrollbar();
      }

      &__option {
        color: $custom-select-color;
        line-height: $custom-select2-height;
        padding: 0 $custom-select-padding-x;
        border-top: $custom-select-border-width solid $custom-select-border-color;

        &--highlighted[aria-selected],
        &[aria-selected="true"] {
          color: $custom-select-color;
          background-color: #f1f1f1;
        }
      }
    }
  }

  .is-invalid + & {
    .select2 {
      &-selection {
        &--single {
          border-color: $danger;
        }
      }
    }
  }
}
