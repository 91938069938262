.loyalty-box {
  padding: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: space-between;
  color: $white;

  .page-title {
    max-width: 300px;
    font-weight: 500;
  }

  .custom-control-label {
    color: $white;
  }

  p {
    line-height: 1.6;
  }

  img {
    width: 100%;
    padding: 1rem;
  }

  .loyalty-box-cta {
    font-size: rem-calc(14);
    text-decoration: underline;
    color: $white;
  }
}

.loyalty-code {
  background-color: $white;
  color: $black;

  &__code {
    padding: 1rem;

    &.active {
      .loyalty-code__wrapper {
        opacity: 1;
        transform: translateY(0);
        transition: 0.2s ease all;
        will-change: opacity, transform;
      }
    }
  }

  &__wrapper {
    align-items: center;
    opacity: 0;
    transform: translateY(-5px);
  }
}
