/*
 * Bootstrap's native variables for spacing
*/

$spacer: rem-calc(20);
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: (
  5: ($spacer * 2),
  6: ($spacer * 3)
);

/*
 * Bootstrap's native variables for grid generation
*/

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1600px
);

$container-max-widths: (
  sm: 768px,
  md: 1024px,
  lg: 1280px
);

/*
 * Variables for custom responsive gutters, generated by grid mixins in ~common/bootstrap
*/
$grid-gutter-widths: (
  xs: 15px,
  sm: 16px,
  md: 12px,
  lg: 12px
);

$grid-gutter-width: map-get($grid-gutter-widths, xs);

$container-gutter-widths: (
  xs: 30px,
  sm: 40px,
  md: 64px,
  lg: 80px
);

$container-gutter-width: map-get($container-gutter-widths, xs);

$default-border: 1px solid $gray-200;
$default-border-dark: 1px solid $gray-900;

// Navbar
$navbar-heights: (
  xs: 57,
  sm: 57,
  md: 100,
  lg: 100
);

// Navbar Checkout
$navbar-checkout-heights: (
  xs: 57,
  sm: 57,
  md: 100,
  lg: 100
);

// Suggestions
$suggestions-heights: (
  sm: 430,
  md: 365,
);

$spinner-background: $white;

$alert-padding-y: 0.75rem !default;
$alert-padding-x: $alert-padding-y;
