.minicart {
  position: relative;

  .card.product-info {
    border: 0;
  }

  h1 {
    font-size: 1rem;
  }

  .cart {
    padding-top: 0.625em;
    padding-bottom: 0.625em;
    background-color: $body-bg;
  }

  .product-summary {
    margin-right: -0.938em;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0.938em;

    /* ===== Scrollbar CSS ===== */

    /* Firefox */
    scrollbar-width: auto;
    scrollbar-color: $black $gray-500;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $gray-500;
      margin-block: rem-calc(60);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black;
    }

    .item-image {
      img {
        object-fit: cover;
      }
    }
  }

  .card-body {
    padding: 0.625em;
  }

  .quantity-label {
    font-size: 0.813em;
  }

  .quantity {
    width: 100%;
  }

  .popover {
    top: 100%;
    left: auto;
    right: 0;
    min-width: 23.44rem;
    max-width: 23.44rem;
    min-height: 22.7rem;
    display: none;

    &::before {
      left: auto;
      right: 15px;
    }

    &::after {
      left: auto;
      right: 16px;
    }

    &.show {
      display: block;
    }
  }

  .estimated-total {
    margin-top: 0.625em;
  }

  .sub-total-label {
    font-size: 1em;
    font-weight: 600;
  }

  .sub-total {
    font-size: 1em;
    font-weight: 600;
  }

  .hide-shipping-discount {
    display: none;
  }

  .line-item-divider {
    margin: 0.625em -0.625em 0.625em -1.325em;
  }

  .line-item-name {
    width: 90%;
  }

  &--wrapper {
    height: 100%;
  }
}

.hide-link-med {
  @include media-breakpoint-only(sm) {
    display: none;
  }
}

.hide-no-link {
  @include media-breakpoint-up(md) {
    display: none;
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.panel.panel--wishlist {
  @include media-breakpoint-up(lg) {
    width: rem-calc(450);
  }

  @include media-breakpoint-up(xl) {
    width: rem-calc(520);
  }
}

.panel.panel--minicart {
  @include media-breakpoint-up(lg) {
    width: rem-calc(450);
    position: fixed;
    overflow-x: hidden;
  }

  @include media-breakpoint-up(xl) {
    width: rem-calc(520);
  }

  height: auto;

  .icon-betterdenim {
    bottom: 0;
    right: 0;
  }

  &:not(.search-clmr):not(.size-chart__panel) .container {
    padding-inline: 0 !important;

    p {
      margin-block: auto;
    }
  }
}
