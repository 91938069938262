@import "../experience/components/mixins";

.region-timeline {
  text-align: center;
  margin: 0 0.2rem 0 0.4rem;

  @include media-breakpoint-up(lg) {
    margin: 0 1rem;
  }

  .timeline-title {
    font-size: rem-calc(55);
    font-weight: 300;
    line-height: rem-calc(60);
    margin-bottom: rem-calc(23);
    margin-top: 2rem;

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(130);
      line-height: rem-calc(120);
      margin-bottom: rem-calc(50);
    }
  }

  .timeline-paragraph {
    font-size: rem-calc(14);
    line-height: rem-calc(20);
  }
}

.timeline-carousel {
  margin-bottom: rem-calc(60);
  margin-top: rem-calc(32);

  @include media-breakpoint-up(lg) {
    margin-top: rem-calc(46);
    margin-bottom: rem-calc(48);
  }

  .image-wrapper {
    flex: 1;

    img {
      object-fit: cover;
      aspect-ratio: 5/3;
    }
  }

  .carousel-indicators {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    margin-inline: auto;
    padding: 0;

    @include media-breakpoint-up(lg) {
      margin-inline: 0;
      left: 0%;
      right: 0%;
    }

    button {
      display: inline-block;
      height: 2px;
      background-color: #d9d9d9;
      cursor: pointer;
      border: none;
      padding: 0;

      &:last-child {
        margin: 0;
      }

      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }
    }

    button.active {
      background-color: #4c4c4c;
    }
  }

  .timeline_indicators {
    height: 3px;

    .indicator {
      cursor: pointer;
      background-color: $gray-500;

      &:focus-visible,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .indicator.active {
      background-color: $black;
    }
  }

  .image-carousel-slide {
    &__container {
      display: flex;
      margin: auto;
      flex-direction: column;
      text-align: justify;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }

    &__date {
      font-size: rem-calc(48);
      line-height: 85%;
      font-weight: 300;
      letter-spacing: 0.24px;
      padding: rem-calc(16);
      font-family: $headings-font-family;

      @include media-breakpoint-up(lg) {
        position: absolute;
        left: 0;
        padding: 0;
        transform: translateX(-73%);
        font-size: rem-calc(90);
        line-height: rem-calc(85);
        max-width: 17rem;
        text-align: left;
        letter-spacing: 0.45px;
        font-weight: 300;

        &.center {
          top: 50%;
          transform: translate(-73%, -50%);
        }

        &.top {
          top: 3rem;
          bottom: auto;
        }

        &.bottom {
          bottom: 3rem;
          top: auto;
        }
      }
    }

    &__text {
      padding: 0 rem-calc(16);
      width: 100%;
      font-size: rem-calc(12);
      line-height: rem-calc(16);
      letter-spacing: 0.05px;
      font-family: $font-family-sans-serif;

      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        padding: 0;
        letter-spacing: 0.06px;
        max-width: 12.5vw;
        position: absolute;
        right: 2.5rem;

        &.center {
          top: 50%;
          transform: translateY(-50%);
        }

        &.bottom {
          bottom: 3rem;
          top: auto;
        }

        &.top {
          top: 3rem;
          bottom: auto;
        }
      }
    }
  }

  .swiper-container {
    width: 100%;
  }

  .slide-transition {
    .swiper-wrapper {
      @include media-breakpoint-up(lg) {
        transition-duration: 1s !important;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
      }
    }

    .swiper-slide {
      @include media-breakpoint-up(lg) {
        transform: scale(0.85);
        transition-duration: 1s;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
      }

      .image-carousel-slide {
        &__container {
          @include media-breakpoint-up(lg) {
            transition-duration: 1s;
            transition-property: padding;
            transition-timing-function: cubic-bezier(0.6, 0.2, 0.4, 1);
          }
        }

        &__date,
        &__text {
          transition-duration: 1s;
          transition-property: transform;
          transition-timing-function: cubic-bezier(0.6, 0.2, 0.4, 1);
        }
      }
    }

    .swiper-slide-active {
      @include media-breakpoint-up(lg) {
        transform: scale(1);
        transition-duration: 1s;
        transition-property: all;
        transition-timing-function: linear;
      }
    }
  }

  .swiper-slide {
    text-align: center;
    width: auto;

    @include media-breakpoint-up(lg) {
      transform: scale(0.85);
    }

    .image-carousel-slide {
      &__container {
        @include media-breakpoint-up(lg) {
          padding: 0 24.6% 0 19.3%;
        }
      }
    }
  }

  .swiper-slide-active {
    @include media-breakpoint-up(lg) {
      transform: scale(1);
    }

    .image-carousel-slide {
      &__container {
        @include media-breakpoint-up(lg) {
          padding: 0 24.6% 0 19.3%;
        }
      }
    }
  }

  .swiper-slide-next {
    @include media-breakpoint-up(lg) {
      .image-carousel-slide {
        &__container {
          padding: 0;
          padding-right: 43.9%;
        }

        &__date {
          &.center {
            transform: translate(150%, -73%);
          }

          &.top,
          &.bottom {
            transform: translateX(150%);
          }
        }
      }
    }
  }

  .swiper-slide-prev {
    @include media-breakpoint-up(lg) {
      .image-carousel-slide {
        &__container {
          padding: 0;
          padding-left: 33.4vw;
        }

        &__text {
          &.center {
            transform: translate(-150%, -50%);
          }

          &.top,
          &.bottom {
            transform: translateX(-150%);
          }
        }
      }
    }
  }

  .hub-carousel__grid {
    padding-right: 0;
  }

  .swiper-scrollbar-drag {
    @include media-breakpoint-up(lg) {
      transition-duration: 1s !important;
    }
  }

  .timeline__indicators {
    margin-top: 3.5rem;

    &_wrapper {
      height: 2px;
      background-color: #d9d9d9;
      padding: 0;
    }

    .swiper-scrollbar-drag {
      background-color: $black;
      height: 2px;
    }
  }

  .carousel-control-prev {
    opacity: 1;
    transition: opacity 0.5s ease;
    width: fit-content;
    left: 10%;

    &.disabled {
      opacity: 0;
    }

    &-icon {
      transform: rotate(180deg);
      background-image: url(escape-svg("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='13' viewBox='0 0 10 13' fill='black'%3E%3Cpath fill-rule='evenodd' stroke='black' stroke-linecap='square' stroke-width='1.5' d='M3 1.5l5 5m0 0l-5 5'%2F%3E%3C%2Fsvg%3E"));
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      opacity: 0.8;
      padding: 0.5rem;
      border-radius: 50%;

      &:hover {
        border: 1px solid $black;
      }
    }
  }

  .carousel-control-next {
    opacity: 1;
    transition: opacity 0.3s ease;
    width: fit-content;
    right: 10%;

    &.disabled {
      opacity: 0;
    }

    &-icon {
      background-image: url(escape-svg("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='13' viewBox='0 0 10 13' fill='black'%3E%3Cpath fill-rule='evenodd' stroke='black' stroke-linecap='square' stroke-width='1.5' d='M3 1.5l5 5m0 0l-5 5'%2F%3E%3C%2Fsvg%3E"));
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      opacity: 0.8;
      padding: 0.5rem;
      border-radius: 50%;

      &:hover {
        border: 1px solid $black;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
      top: 50%;
      transform: translateY(-3rem);
      bottom: auto;
    }
  }
}

.timeline-carousel__carousel-section {
  margin-left: auto;
  padding: 0;
}
