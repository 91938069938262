@keyframes shift-ltr {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-40%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes shift-rtl {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(40%);
  }

  100% {
    transform: translateX(0%);
  }
}

@mixin defaultView {
  .product-tile {
    position: relative;
    overflow: hidden;

    &__quick-actions {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 2;
      transform: translate3d(0, 100%, 0);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      .quickSize__callout {
        font-weight: $font-weight-normal;
      }

      .state-selected {
        text-decoration: underline;
      }

      @include media-breakpoint-down(md) {
        width: 100vw;
        opacity: 0;
        transition: opacity 350ms ease-in-out;
        position: fixed;
        bottom: 0;
        transform: translate3d(0, 0, 0);
        z-index: -999;

        &.mobile-visible {
          z-index: 30002;
          opacity: 1;
        }
      }
    }

    &__sizes-wrapper {
      color: $black;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      min-height: 240px;
      background-color: rgba($white, 0.9);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-family: 'Jost', sans-serif;

      // &:hover:not(.state-active) {
      //   background: transparentize($color: $white, $amount: 0.6);
      // }

      @include media-breakpoint-down(md) {
        background-color: $white;
        padding-top: rem-calc(37);
        padding-bottom: 0;
      }

      &.state-visible {
        opacity: 1;

        @include media-breakpoint-down(md) {
          opacity: 1;
        }
      }

      &.state-hidden {
        display: none;
      }

      &.state-selected {
        background-color: $black;
      }

      .product-tile__close {
        cursor: pointer;
      }

      .omnibus-price {
        padding-top: 0 !important;
        align-items: flex-end;
        padding-bottom: rem-calc(20);
        justify-content: center;
        display: flex;

        .price-original-container {
          text-align: center;
        }
      }

      .omnibus-price,
      .omnibus-price-form {
        padding-top: rem-calc(34);

        @include media-breakpoint-down(md) {
          padding-top: rem-calc(10);
          border-top: 0.75px solid #a3a1a2;
        }

        &,
        & * {
          font-size: rem-calc(12);
          line-height: rem-calc(12);

          @include media-breakpoint-down(md) {
            font-size: rem-calc(14);
            line-height: rem-calc(14);
          }
        }

        .price {
          margin-top: 0 !important;
          font-weight: $font-weight-bold;

          .price-original-container {
            margin: 0 !important;
            font-size: 10px !important;
          }
        }
      }
    }

    &__size-btn {
      padding: rem-calc(2);
      min-width: unset;
      color: $black;
      border: 1px solid black;
      width: 40px;
      height: 40px;
      font-size: 11.5px;
      margin: 0.4rem;

      &:hover {
        font-style: initial !important; // Override defaults
        background-color: transparent;
      }

      &.state-selected {
        text-decoration: underline;
      }

      &.state-disable {
        border: 1px solid #cccbcb;
        color: #cccbcb;
        background: transparent;
        cursor: pointer;
        text-decoration: line-through;
      }

      &.state-active {
        background: $white;
        color: $black;
      }

      &:not(.state-selectable):not(.state-disable) {
        text-decoration: none;
      }
    }
  }
}

body:has(.product-tile__quick-actions.mobile-visible) {
  .veil {
    z-index: 1042;
  }
}

.product-tile {
  @include defaultView;

  &__image {
    max-width: 100%;
  }

  &__presentation {
    overflow: hidden;

    &.fixed-touch-action {
      touch-action: auto !important;
    }
  }

  &__close {
    position: absolute;
    top: rem-calc(40);
    right: 10px;

    @include media-breakpoint-down(md) {
      top: rem-calc(37);
    }
  }

  &__body {
    font-size: rem-calc(12);
    line-height: rem-calc(15);

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 0;

    @include media-breakpoint-up(lg) {
      transition: all ease-in-out 0.25s;
    }
  }

  @include media-breakpoint-up(lg) {
    &:has(.carousel.slide:hover) {
      .carousel-control-prev,
      .carousel-control-next {
        opacity: 1;
      }
    }
  }

  .size-toggler.position-absolute {
    bottom: 5px;
    right: rem-calc(21);

    .plus-icon {
      z-index: 2;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      cursor: pointer;
      height: rem-calc(18);
      margin-top: -0.64286rem;
      margin-left: -0.64286rem;
      position: absolute;
      width: rem-calc(18);

      &::before,
      &::after {
        content: "";
        position: absolute;
        background: $black;
      }

      &::before {
        left: 50%;
        top: 50%;
        width: 0.07143rem;
        height: rem-calc(9);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      &::after {
        top: 50%;
        left: 50%;
        height: 0.07143rem;
        width: rem-calc(9);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }

  @include media-breakpoint-down(md) {
    .mobileSize-background.size-visible {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1040;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__quick-actions {
    .cart-and-ipay {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($white, 0.9);
      transition: 0.3s ease top;
      will-change: top;

      @include media-breakpoint-down(md) {
        background-color: $white;
      }

      .add-to-cart-wishlist {
        .btn {
          min-width: 100%;
          margin-bottom: 0;
        }
      }

      &.show {
        top: 0;
        z-index: 1;
        display: flex;
      }

      &__close {
        position: absolute;
        top: rem-calc(40);
        right: rem-calc(10);
        cursor: pointer;

        @include media-breakpoint-down(lg) {
          top: rem-calc(34);
        }
      }

      .label-select-size {
        &::before {
          content: '';
          display: inline-block;
          width: rem-calc(6);
          height: rem-calc(6);
          margin-right: $spacer * 0.5;
          background-color: $beige;
          font-size: rem-calc(16) !important;
          margin-bottom: 2px;

          @include border-radius(50%);
        }
      }

      .email-label {
        text-transform: none;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        padding-bottom: rem-calc(19);
        margin-bottom: 0;
      }

      .form-control {
        border: rem-calc(1) solid $black;
        height: rem-calc(44);
        font-size: rem-calc(16);
        line-height: rem-calc(21);

        .btn {
          margin-bottom: 0;
        }
      }

      .form-text.success-message {
        font-size: rem-calc(12);
        line-height: rem-calc(18);
      }
    }
  }
}

.toggleable-row--compact {
  .size-toggler {
    @include media-breakpoint-down(md) {
      right: rem-calc(5);
      bottom: rem-calc(120);
    }
  }
}

html:has(.toggleable-row:not(.toggleable-row--compact)) {
  &,
  body {
    overflow: unset;

    .nav-open {
      overflow-x: clip;
    }
  }

  .toggleable-row.product-grid .toggleable-col:first-of-type {
    .tile-body {
      position: sticky;
      bottom: 0;
      z-index: 2;
    }
  }

  @include media-breakpoint-up(md) {
    //select first 2 .toggleable-col
    .toggleable-row.product-grid .toggleable-col:nth-child(-n + 2 of .toggleable-col) {
      .tile-body {
        position: sticky;
        bottom: 0;
        z-index: 2;
        min-height: rem-calc(120);
      }
    }
  }

  body:has(.nav-open),
  body:has(.site-search.panel.show) {
    .toggleable-row.product-grid .toggleable-col {
      .tile-body {
        z-index: 0;
      }
    }
  }
}

.product-tile .slide-animated.animate {
  animation: shift-ltr 0.5s linear;
  animation-delay: 1s;
}
