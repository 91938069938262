.nav {
  &-link {
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    font-size: rem-calc(12);
    line-height: rem-calc(24);
    letter-spacing: rem-calc(1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem-calc(13) 0;
    text-transform: uppercase;
    text-decoration: none !important;

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(16);
      padding: rem-calc(11) 0;
    }

    @include media-breakpoint-up(xl) {
      font-size: rem-calc(20);
      line-height: rem-calc(35);
    }

    &__group {
      img {
        margin-right: $spacer;
        max-width: rem-calc(90);

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    &__label {
      display: none;
      margin-left: rem-calc(16);
      font-size: rem-calc(14);
      line-height: rem-calc(18);

      &--home {
        [data-level="2"] & {
          display: inline;
        }
      }

      &--all {
        [data-level="3"] & {
          display: inline;
        }
      }
    }

    &.special {
      color: $fucsia;
    }

    .extra-label {
      color: $black;
      font-weight: $font-weight-bolder;
      font-family: $headings-font-family;
      text-transform: none;
      vertical-align: super;
    }
  }

  .nav-banner {
    display: block;
    margin-bottom: $spacer;

    img {
      display: block;
      width: 100%;
      margin-bottom: $spacer * 0.5;

      @include media-breakpoint-up(lg) {
        margin-bottom: $spacer;
      }
    }

    @include media-breakpoint-up(lg) {
      flex: 1 0;
      margin-top: $spacer;
    }
  }

  .submenu {
    // Second Level
    .nav-item {
      .navbar-back {
        margin-top: rem-calc(-4);
      }
    }

    .nav-link {
      font-family: $font-family-sans-serif;
      font-weight: $font-weight-bold;
      font-size: rem-calc(14);
      line-height: rem-calc(18);
      text-transform: uppercase;

      &--view-all {
        justify-content: flex-start;
      }
    }

    .nav-banners {
      position: relative;

      h3 {
        @include media-breakpoint-up(lg) {
          font-size: rem-calc(28);
          line-height: rem-calc(35);
          font-weight: 400;
        }
      }
    }

    .submenu {
      // Third level
      .nav-link {
        font-size: rem-calc(13);
        line-height: rem-calc(17);
        text-transform: none;

        &--view-all {
          justify-content: flex-start;
        }
      }

      .nav-banners {
        display: none;
        margin-top: $spacer;

        @include media-breakpoint-up(lg) {
          position: absolute;
          padding-left: rem-calc(map-get($grid-gutter-widths, sm));
          margin-top: 0;
          right: 0;
          left: 33.3333%;
          top: $spacer * 0.5;
          width: 66.6666%;
          display: flex;
          justify-content: flex-end;

          .nav-banner {
            flex: 0 0 50%;
          }
        }
      }

      // Alternative layout for 2 column version
      &--2-columns {
        @include media-breakpoint-up(lg) {
          .nav-banners {
            left: 33.3333%;
          }
        }
      }
    }
  }
}

%root-nav-item {
  display: inline-block;
  padding-right: $spacer * 2;

  &:last-child {
    padding-right: $spacer;
  }

  @include media-breakpoint-up(lg) {
    padding-right: 0;
    margin-right: $spacer * 1.5;

    &:last-child {
      padding-right: 0;
      margin-right: 0;
    }
  }
}
