.message-wishlist {
  display: none;
  bottom: 0;
  left: 0;
  z-index: 9;
  height: rem-calc(50);
  background-color: $black;
  color: $white;
  width: 100%;

  @include media-breakpoint-up(md) {
    // top: 0;
    // right: 0;
    left: auto;
    width: auto;
    bottom: auto;
  }

  span {
    font-size: rem-calc(16);
    line-height: rem-calc(16);
    font-weight: 500;
    left: 20px;
  }

  a {
    text-decoration: underline;
    font-weight: $font-weight-bold;
    right: 20px;
  }

  span,
  a {
    top: 50%;
    transform: translateY(-50%);
  }
}
