@import "../experience/components/mixins";

.region-care {
  padding-bottom: rem-calc(35);
  background-color: #eff0f2;

  @include media-breakpoint-up(lg) {
    padding: rem-calc(63) rem-calc(50);

    &.inverted {
      .wrapper-region-care {
        flex-direction: row-reverse !important;
      }
    }
  }

  .container-title-mobile {
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;
  }

  .wrapper-region-care {
    justify-content: space-between;
    align-items: center;
    margin: auto;
    row-gap: 1.5rem;

    @include media-breakpoint-down(md) {
      flex-direction: column !important;
    }
  }

  .container-care,
  .container-media {
    flex: 1;
  }

  .lazyload-container {
    background-color: transparent;
  }

  .container-media {
    width: 100%;

    @include media-breakpoint-up(lg) {
      padding: 0;
      max-width: 44%;
    }

    margin: 0 0 auto;

    img {
      aspect-ratio: 1/1;
      object-fit: cover;
    }

    video {
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }

  .swiper {
    &:not(.swiper-initialized) {
      .swiper-wrapper {
        column-gap: 3.9vw;
      }
    }
  }

  .swiper-wrapper {
    padding: 0;
    justify-content: space-between;

    .image-carousel-slide__container {
      height: 100%;
      width: 100%;
      background: transparent;
    }

    .image-wrapper {
      height: 100%;
      width: 100%;

      @include media-breakpoint-up(lg) {
        border-bottom: 2px solid #a3a2a2;
        padding-bottom: rem-calc(14);
      }

      @include media-breakpoint-up(lg) {
        &.active {
          border-bottom: 2px solid $black;
        }
      }
    }

    .swiper-slide {
      flex: 1;
      position: relative;
      height: fit-content;

      .image-carousel-slide__title {
        display: none;
      }

      @include media-breakpoint-up(lg) {
        .image-carousel-slide__title {
          opacity: 0;
          visibility: hidden;
          color: $white;
          position: absolute;
          top: 0;
          left: 0;
          height: calc(100% - 1rem);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.6);
          transition: opacity 0.3s ease, visibility 0.3s linear 0.3s;
          padding: 0 0.5rem;
        }

        &:hover {
          .image-carousel-slide__title {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
          }
        }
      }
    }

    img {
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }

  .container-copy {
    display: none;
  }

  .section-copy {
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(20);

    @include media-breakpoint-up(lg) {
      position: relative;
      padding-bottom: rem-calc(24);
    }

    .copy-title {
      font-size: rem-calc(40);
      line-height: rem-calc(58);
      letter-spacing: 0.8px;
      margin: 0;
    }

    .image-carousel-slide__text {
      margin-bottom: rem-calc(20);
    }

    .image-carousel-slide__container {
      display: none;
    }

    .image-carousel-slide__cta {
      font-weight: bold;
      font-size: rem-calc(16);
      line-height: rem-calc(23);
      border-bottom: 1px solid;

      @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: rem-calc(0);
      }
    }

    .container-copy {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .care-title {
    font-size: rem-calc(70);
    line-height: rem-calc(81);
    font-weight: 400;
    color: $white;
    margin: auto;
  }

  .container-care {
    @include media-breakpoint-up(lg) {
      max-width: 43.5%;
    }

    .care-title {
      color: $black;
      margin-bottom: 0.5rem;
    }

    .care-subtitle {
      font-size: rem-calc(16);
      line-height: rem-calc(23);
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 0;
    }

    .care-copy {
      font-size: rem-calc(14);
      line-height: rem-calc(20);
      margin-bottom: rem-calc(40);
    }
  }

  .carousel-indicators {
    margin: rem-calc(25) auto 0;
    position: absolute;
    bottom: -2rem;
    padding-left: rem-calc(7.5);
    z-index: 1;

    button {
      display: inline-block;
      height: 3px;
      background-color: #a7a7a7;
      margin: 0 6px 0 0;
      cursor: pointer;
      border: none;
      padding: 0;

      &:last-child {
        margin: 0;
      }
    }

    button.active {
      background-color: $black;
    }
  }

  .care-carousel__carousel-section {
    @include media-breakpoint-down(md) {
      margin-bottom: rem-calc(44);
    }
  }
}
