.cart,
.product-detail,
.checkout-container {
  .apple-pay-wrapper {
    .dw-apple-pay-button {
      margin-bottom: 20px !important;
      width: 100%;
      display: inline-block;
      height: 41px;
      vertical-align: top;
      min-height: 41px;
      max-height: 41px;
      background-color: #fff !important;
      background-image: -webkit-named-image(apple-pay-logo-black) !important;
      background-position: 50% 50% !important;
      background-repeat: no-repeat !important;
      background-size: 75% 60% !important;
      border: 1px solid black;
      border-radius: 0;

      &:hover {
        border-radius: 0 !important;
      }
    }
  }
}

.apple-pay-wrapper {
  .dw-apple-pay-button {
    height: rem-calc(52) !important; // Same height of a CTA
    min-height: rem-calc(52) !important;
    max-height: rem-calc(52) !important;

    @include media-breakpoint-down(md) {
      height: rem-calc(34) !important;
      min-height: rem-calc(34) !important;
      max-height: rem-calc(34) !important;
      overflow: hidden;
    }
  }
}
