body {
  &::before,
  &::after {
    display: none !important;
    visibility: hidden !important;
  }

  $breakpoints-list: "";

  @each $name, $value in $grid-breakpoints {
    $breakpoints-list: $breakpoints-list + $name + ':' + $value + ',';
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      &::before {
        content: $breakpoints-list;
      }

      &::after {
        content: ''+$breakpoint;
      }
    }
  }
}
