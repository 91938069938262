.image-badge {
  display: flex;
  flex-wrap: wrap;
  pointer-events: none;
  text-transform: uppercase;
  row-gap: rem-calc(8);

  @include media-breakpoint-down(sm) {
    align-items: flex-start;
    flex-direction: row;
  }

  &__item {
    $labelSpace: rem-calc(6);

    font-size: rem-calc(9);

    @include media-breakpoint-up(sm) {
      font-size: rem-calc(14);
    }

    align-self: self-start;

    @include smaller;

    margin: 0 $labelSpace 0 0;
    padding: rem-calc(2) $labelSpace;

    &_red {
      background-color: $red;
      color: $white;

      @include media-breakpoint-down(sm) {
        margin-top: rem-calc(8) !important;
      }
    }

    &_green {
      background-color: $green;
      color: $white;

      @include media-breakpoint-down(sm) {
        margin-top: rem-calc(8) !important;
      }
    }

    &_gray {
      background-color: rgba(163, 162, 162, 1);
      color: $white;
    }

    &_white {
      background-color: $white;

      &_layout {
        background-color: rgba(255, 255, 255, 0.4);
        color: $white;
      }
    }

    &_black {
      background-color: $black;
      color: $white;

      &_layout {
        background-color: rgba($black, 0.4);
        color: $white;
      }

      &_new {
        font-family: "Jost", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: $font-weight-normal;
        color: $black;
      }
    }
  }
}

.product {
  &--grid-tile,
  &-detail {
    .image-container,
    .image {
      position: relative;

      .image-badge {
        top: rem-calc(16);
        left: rem-calc(18);
        position: absolute;
        z-index: 1;

        @include media-breakpoint-up(sm) {
          top: rem-calc(27);
          left: rem-calc(56);
        }

        @include media-breakpoint-up(md) {
          top: rem-calc(27);
          left: rem-calc(56);
        }
      }
    }
  }

  &-detail {
    .image {
      .image-badge {
        bottom: rem-calc(20);
        left: rem-calc(25);

        @include media-breakpoint-up(lg) {
          bottom: rem-calc(27);
          left: rem-calc(40);
        }
      }
    }

    .primary-images__slider {
      .image-badge {
        position: absolute;
        top: rem-calc(16);
        left: rem-calc(18);
      }
    }
  }
}
