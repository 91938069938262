.pre-header {
  z-index: 3;
  background: $black;
  position: relative;

  #multiple-text {
    font-size: rem-calc(12);

    @include media-breakpoint-up(xl) {
      font-size: rem-calc(14);
    }
  }
}

.page-header {
  // Menu scrim
  &::before {
    position: fixed;
    top: rem-calc(map-get($navbar-heights, xs) + 80);
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($black, 0.3);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: 250ms ease;

    @include media-breakpoint-up(lg) {
      content: "";
    }
  }

  &.nav-open {
    z-index: 4;
    position: relative;

    &::before {
      opacity: 1;
      visibility: visible;
    }
  }

  // in case of rollback (for body background on modal open only with submenu) decomment next block
  // &[data-level="1"],
  // &[data-level="2"] {
  //   &::before {
  //     @include media-breakpoint-up(lg) {
  //       opacity: 0;
  //     }
  //   }
  // }

  @import "sticky";
}

.nav {
  background: $white;
  position: relative;
  z-index: 3;

  ul {
    padding: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  &-checkout,
  &-error {
    .btn {
      width: auto;
      height: auto;
      min-width: auto;
      min-height: auto;

      &-icon {
        &.left {
          left: 0;
        }
      }
    }
  }
}

.navbar {
  padding: rem-calc(8) 0;
  height: rem-calc(map-get($navbar-heights, xs));
  // transition: height $transition-duration $transition-timing;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    height: rem-calc(map-get($navbar-heights, sm));
    padding: rem-calc(14) 0;
  }

  @include media-breakpoint-up(xl) {
    height: rem-calc(map-get($navbar-heights, md));
    padding: rem-calc(14) 0;
  }

  &-checkout,
  &-error {
    @include media-breakpoint-up(lg) {
      height: rem-calc(map-get($navbar-checkout-heights, md));
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__toggler {
    margin-right: $spacer;

    .navbar-close {
      display: none;
    }
  }

  &__brand {
    flex: 1 10;

    @include logo();

    .nav-checkout &,
    .nav-error & {
      max-width: rem-calc(130);
    }
  }

  &__menu {
    @include media-breakpoint-up(lg) {
      .submenu {
        display: none;
      }
    }
  }

  &__submenu {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__action {
    a,
    button,
    .btn {
      display: block;
      margin-left: $spacer;
      position: relative;

      .isicon {
        width: rem-calc(20) !important;
        height: rem-calc(20) !important;

        @include media-breakpoint-up(lg) {
          width: rem-calc(24) !important;
          height: rem-calc(24) !important;
        }

        @include media-breakpoint-up(xl) {
          width: rem-calc(28) !important;
          height: rem-calc(28) !important;
        }
      }
    }
  }
}
