.panel {
  display: none;
  position: fixed;
  width: 100%;
  top: 0;
  right: -100%;
  left: auto;
  height: 100%;
  bottom: 0;
  background: $white;
  transition-property: right, background;
  transition-duration: 600ms, $transition-duration;
  transition-timing-function: $transition-timing;
  z-index: 11;

  @include media-breakpoint-up(sm) {
    width: rem-calc(375);

    &.full {
      width: 100%;
    }
  }

  &.show {
    right: 0;
  }

  &.hiding {
    right: -100%;
  }

  &.loading {
    // background: $gray-200;

    .loader {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:not(.search-clmr):not(.size-chart__panel):not(.search-lj) {
    // Override custom breakpoint external gutters
    .container {
      padding-left: $spacer * 0.75 !important;
      padding-right: $spacer * 0.75 !important;
    }
  }

  &--account {
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }

  &--account::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
}

body {
  & > .page {
    &.panel-open {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        background: $black;
        z-index: 10;
        animation: panelFadeIn $transition-duration $transition-timing forwards;
      }

      &.hiding {
        &::after {
          animation: panelFadeOut $transition-duration $transition-timing forwards;
        }
      }
    }
  }
}

.minicart,
.miniwishlist,
.miniaccount {
  &--wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    .product-summary {
      flex: 1 1;
      overflow-x: hidden;
      overflow-y: auto;

      .continue-shopping-link {
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
    }
  }
}

@keyframes panelFadeIn {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 0.3;
  }
}

@keyframes panelFadeOut {
  from {
    visibility: visible;
    opacity: 0.3;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}
