.info-icon {
  cursor: pointer;

  .tooltip {
    background-color: black;
    opacity: 1;
    color: white;
    top: -10px;
    right: 60px;
    padding: rem-calc(2);
    width: rem-calc(100px);
    margin-right: -20px;
    border-radius: rem-calc(5px);
    text-align: center;

    &::after {
      content: " ";
      position: absolute;
      top: 50%; /* At the bottom of the tooltip */
      left: 100%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent;
    }
  }

  &.loyalty-tooltip-benefit {
    position: relative;

    .tooltip {
      background-color: #c4c4c4;
      opacity: 1;
      color: black;
      top: -3.1rem;
      left: 0;
      bottom: auto;
      padding: 1rem;
      width: 100%;
      margin-left: 0;

      &::after {
        content: " ";
        display: none;
      }
    }
  }

  &.loyalty {
    .tooltip {
      background-color: $white;
      color: $black;
      border: rem-calc(1) solid rgba(240, 241, 241, 1);
      box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1);
      display: block;

      &.nqp,
      &.voucher,
      &.stars,
      &.nqppoints {
        min-width: rem-calc(185);
        top: rem-calc(35);
        right: rem-calc(15);
        padding: rem-calc(10);

        span.font-weight-bold {
          font-weight: 600 !important;
        }

        &::after {
          border-color: $white transparent transparent;
          border-width: rem-calc(20);
          margin-left: rem-calc(-26);
          top: rem-calc(-14);
          left: auto;
          right: rem-calc(4);
          transform: rotate(180deg) translateY(15px);
        }

        @include media-breakpoint-up(md) {
          transform: translateX(65px);

          &::after {
            right: auto;
            left: calc(50% + 12px);
          }
        }
      }

      &.stars {
        @include media-breakpoint-down (md) {
          right: rem-calc(7);
        }

        @include media-breakpoint-up (md) {
          &::after {
            left: calc(50% + 18px);
          }
        }
      }

      @include media-breakpoint-up (md) {
        &.voucher {
          right: rem-calc(78);

          &::after {
            left: calc(50% + 74px);
          }
        }
      }
    }
  }
}
