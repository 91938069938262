.product-info {
  .cart-page &,
  .order-details &,
  .order-product-summary &,
  .wishlist-account-card & {
    padding: $spacer * 0.5;

    @include media-breakpoint-up(md) {
      padding: $spacer;
    }
  }

  .card-body {
    padding: 0;
  }

  label {
    &[class$="-label"] {
      display: none;
    }
  }

  &.card {
    .cart-page &,
    .order-details & {
      &:not(:first-child) {
        border-top: 0;
      }
    }

    .order-product-summary &,
    .wishlist-account-card > .col:not(:first-child) & {
      border-top: 0;
    }

    .product-summary &,
    .myaccount-wishlist & {
      &:not(:first-child) {
        margin-top: $spacer * 0.6;
      }
    }

    .order-details & {
      margin-top: 0;
    }

    .complete-the-look & {
      &:not(:last-child) {
        margin-bottom: $spacer;

        @include media-breakpoint-up(md) {
          margin-bottom: $spacer * 0.6;
        }
      }
    }
  }

  .complete-the-look & {
    .small {
      @include media-breakpoint-up(md) {
        font-size: rem-calc(14);
        line-height: rem-calc(24);
      }
    }
  }

  &__details {
    display: flex;

    .item {
      &-image {
        .cart-page &,
        .wishlist-account-card & {
          position: relative;
          margin-right: $spacer * 0.5;

          @include media-breakpoint-up(md) {
            margin-right: $spacer;
          }
        }
      }

      &-attributes {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
        text-transform: capitalize;

        .icon-betterdenim {
          bottom: 0 !important;
          right: 0 !important;
        }

        .order-details & {
          flex-direction: row;
        }

        &__body {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding: $spacer * 0.5;

          .complete-the-look & {
            padding: $spacer;

            @include media-breakpoint-up(md) {
              padding-right: $spacer * 0.75;
            }
          }

          .order-details &,
          .order-product-summary & {
            padding-top: 0;
            padding-bottom: 0;
          }

          .order-product-summary & {
            padding-right: 0;
          }

          .line-item {
            &-quantity {
              .order-details & {
                display: inline-block;

                @include media-breakpoint-up(sm) {
                  display: none;
                }

                .receipt-container & {
                  @include media-breakpoint-only(sm) {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }

        .line-item {
          &:not(&[class$="name"]) {
            .cart-page &,
            .wishlist-account-card & {
              margin-top: $spacer * 0.15;

              @include media-breakpoint-up(md) {
                margin-top: $spacer * 0.25;
              }
            }
          }

          &-name {
            &,
            & a {
              margin-left: 0;

              .cart-page &,
              .wishlist-account-card &,
              .product-summary &,
              .myaccount-wishlist & {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .complete-the-look & {
                white-space: normal;
                height: rem-calc(38);
                overflow: hidden;
                text-overflow: ellipsis;

                @include media-breakpoint-up(md) {
                  height: rem-calc(48);
                }
              }
            }
          }

          &-availability {
            color: $gray-light;
          }

          &__quantity {
            &--total-price {
              .order-details & {
                @include media-breakpoint-down(xs) {
                  display: flex;
                }

                .qty-card-quantity-label {
                  display: none;
                }
              }
            }
          }

          &-total-price {
            &,
            label {
              text-transform: none;

              .order-product-summary & {
                display: block;
              }
            }

            label {
              font-weight: $font-weight-normal;
            }

            .order-details & {
              @include media-breakpoint-down(xs) {
                display: block;
                margin-left: $spacer * 0.5;
              }

              .receipt-container & {
                @include media-breakpoint-only(sm) {
                  display: inline-block;
                  margin-left: $spacer * 0.5;
                }
              }
            }
          }

          &-attributes {
            &__size-color {
              display: flex;
              flex-direction: column-reverse;
            }

            .product-summary &,
            .myaccount-wishlist &,
            .complete-the-look & {
              &.color {
                display: none;
              }
            }

            .complete-the-look & {
              &.size {
                display: none;
              }
            }
          }

          .myaccount-wishlist &,
          .complete-the-look & {
            &-quantity {
              display: none;
            }
          }
        }

        .wishlist-icon {
          .order-details &,
          .myaccount-wishlist &,
          .product-summary &,
          .order-product-summary & {
            display: none;
          }
        }
      }
    }
  }

  &__order-info {
    display: none;
    white-space: nowrap;

    .order-details & {
      @include media-breakpoint-up(sm) {
        display: flex;
      }

      .receipt-container & {
        @include media-breakpoint-only(sm) {
          display: none;
        }
      }
    }

    .line-item {
      &-quantity {
        margin-right: $spacer * 0.5;

        @include media-breakpoint-up(lg) {
          margin-right: $spacer * 1.5;
        }
      }

      &-total-price {
        display: none;

        .order-details & {
          @include media-breakpoint-up(xs) {
            display: block;
          }
        }
      }
    }

    &--code {
      display: none;
      margin-top: auto;
      color: $gray-700;

      .order-details & {
        display: block;
      }
    }
  }

  &__actions {
    display: flex;
    flex: 1 1 0%;
    align-items: stretch;

    & > .row {
      flex-grow: 1;
      justify-content: flex-end;

      .wishlist-account-card & {
        @include media-breakpoint-down(sm) {
          justify-content: space-between;
        }

        @include media-breakpoint-up(md) {
          align-content: space-between;
          align-items: center;
        }
      }
    }

    .col {
      &.line-item-delete {
        @include media-breakpoint-up(lg) {
          width: 100% !important;
        }
      }

      &:not(.line-item-delete) {
        flex-grow: 0;

        .order-product-summary & {
          align-self: flex-end;
        }
      }
    }

    &--product-card-price {
      flex: 0 0 auto;
      width: auto;
    }

    .line-item {
      white-space: nowrap;
      flex: 0 0 auto;
      width: auto;

      // &-prices {
      //   display: flex;
      //   align-items: flex-end;
      // }

      &-delete,
      &-actions {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: flex-end;

        .wishlist-account-card & {
          .btn {
            &-link {
              &--primary {
                @include media-breakpoint-down(sm) {
                  border-right: 1px solid $card-border-color;
                  border-bottom: 0;
                  padding-right: $spacer * 0.75;
                  margin-right: $spacer * 0.75;
                }

                @include media-breakpoint-up(md) {
                  margin-right: $spacer;
                }

                @include media-breakpoint-up(lg) {
                  margin-right: $spacer * 1.25;
                }
              }
            }
          }
        }
      }

      &[class*="-price"] {
        padding-left: 8px;

        .cart-page & {
          @include media-breakpoint-up(lg) {
            height: rem-calc(35);
            line-height: rem-calc(35);
          }
        }
      }
    }

    .price,
    .pricing {
      .order-product-summary & {
        font-weight: $font-weight-bold;
      }
    }
  }

  .item-image-wishlist {
    width: rem-calc(86);
    min-width: rem-calc(86);
  }

  .item-image {
    height: rem-calc(120);
    width: rem-calc(86);
    min-width: rem-calc(86);

    & .product-image {
      height: 100%;
      object-fit: cover;
    }

    .complete-the-look & {
      width: rem-calc(108);
      min-width: rem-calc(108);
    }
  }

  .form-control {
    width: auto;
    margin-bottom: 0;
  }

  .input-group {
    flex-wrap: nowrap;

    @include media-breakpoint-down(md) {
      margin-top: $spacer;
    }

    .wishlist-account-card & {
      @include media-breakpoint-down(md) {
        margin-top: $spacer * 1.5;
      }

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }

    &-text {
      font-size: rem-calc(12);
      letter-spacing: rem-calc(1);
      text-transform: none;
      border-right: 0;
      background-color: $white;
      padding-right: 0;

      @include media-breakpoint-up(md) {
        font-size: rem-calc(10);
        letter-spacing: rem-calc(0.5);
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &:focus-within {
      .input-group-text {
        border-color: $primary !important;
      }
    }

    .custom-select {
      border-left: 0;
      padding-right: rem-calc(34);

      @include media-breakpoint-up(lg) {
        height: rem-calc(35);
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .remove-product {
    svg {
      fill: $primary;
    }

    .complete-the-look & {
      display: none;
    }
  }

  @import "product/availability";

  .availability-msg {
    & > div {
      margin-bottom: 0px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 0px;
      }
    }
  }
}

.non-adjusted-price {
  display: none;
}

