label {
  text-transform: uppercase;

  @extend .smaller;
}

.form-control {
  margin-bottom: $spacer * 0.5;
  letter-spacing: rem-calc(0.5);

  &.custom-select {
    padding-top: 0;
    padding-bottom: 0;
    line-height: $custom-select-line-height;
  }
}

.form-text {
  // position: absolute;
}

$custom-control-indicator-size-sm: rem-calc(22);
$custom-control-indicator-size-md: rem-calc(16);

.custom-control {
  padding-left: $custom-control-indicator-size-sm;

  @include media-breakpoint-up(md) {
    padding-left: $custom-control-indicator-size-md;
  }

  &--mirrored {
    padding-left: 0;
    padding-right: $custom-control-indicator-size-sm;

    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: $custom-control-indicator-size-md;
    }
  }
}

.label {
  &-link {
    cursor: pointer;
    font-family: $headings-font-family;
    font-size: rem-calc(13);
    font-weight: $font-weight-semibold;
    line-height: rem-calc(15);
    letter-spacing: rem-calc(1);
    text-transform: none;
    text-decoration: none;
    border-bottom: 1px solid $black;
  }
}

.custom-control-label {
  font-size: rem-calc(12);
  color: $gray-700;
  letter-spacing: 0;
  line-height: $custom-control-indicator-size-sm;
  text-transform: none;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    line-height: $custom-control-indicator-size-md;
  }

  .custom-checkbox &,
  .custom-radio & {
    padding-left: $spacer * 0.5;

    &::before,
    &::after {
      width: $custom-control-indicator-size-sm;
      height: $custom-control-indicator-size-sm;
      top: 0;
      left: -$custom-control-indicator-size-sm;

      @include media-breakpoint-up(md) {
        width: $custom-control-indicator-size-md;
        height: $custom-control-indicator-size-md;
        left: -$custom-control-indicator-size-md;
      }

      .bg-600-light & {
        border-color: $gray-400;
        background-color: rgba(#f1f1f1, 0);
      }
    }
  }

  // Mirrored versions, control on the right
  .custom-control--mirrored & {
    padding-left: 0;
    padding-right: $spacer * 0.5;

    &::before,
    &::after {
      left: auto;
      right: -$custom-control-indicator-size-sm;

      @include media-breakpoint-up(md) {
        left: auto;
        right: -$custom-control-indicator-size-md;
      }
    }
  }
}

.custom-radio {
  .custom-control-input:checked {
    & ~ .custom-control-label {
      &::after {
        @include media-breakpoint-up(md) {
          background-image: escape-svg($custom-radio-indicator-icon-checked--medium);
        }
      }
    }
  }
}

.form-group {
  @include media-breakpoint-up(md) {
    margin-bottom: rem-calc(20);

    .custom-select {
      margin-bottom: 0;
    }
  }

  .custom-select-disable {
    background: none;
    background-color: $white !important;
    pointer-events: none;
    color: $gray-1000;
  }

  &.is-invalid {
    .invalid-feedback {
      display: block;
      margin: $spacer * 0.5 0;
    }
  }
}
