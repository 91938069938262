html,
body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  &.modal-open {
    padding: 0 !important;
  }
}

img {
  max-width: 100%;
}

strong,
.strong {
  font-weight: $font-weight-bold;
}
