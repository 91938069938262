/* cyrillic */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Cyrillic.woff2');
  font-weight: 200;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin-Ext.woff2');
  font-weight: 200;
  font-display: swap;
}

/* latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin.woff2');
  font-weight: 200;
  font-display: swap;
}

/* cyrillic */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Cyrillic.woff2');
  font-weight: 300;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin-Ext.woff2');
  font-weight: 300;
  font-display: swap;
}

/* latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin.woff2');
  font-weight: 300;
  font-display: swap;
}

/* cyrillic */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-cyrillic.woff2');
  font-weight: 400;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin-Ext.woff2');
  font-weight: 400;
  font-display: swap;
}

/* latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin.woff2');
  font-weight: 400;
  font-display: swap;
}

/* cyrillic */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-cyrillic.woff2');
  font-weight: 500;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin-Ext.woff2');
  font-weight: 500;
  font-display: swap;
}

/* latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin.woff2');
  font-weight: 500;
  font-display: swap;
}

/* cyrillic */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-cyrillic.woff2');
  font-weight: 600;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin-Ext.woff2');
  font-weight: 600;
  font-display: swap;
}

/* latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin.woff2');
  font-weight: 600;
  font-display: swap;
}

/* cyrillic */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-cyrillic.woff2');
  font-weight: 700;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin-Ext.woff2');
  font-weight: 700;
  font-display: swap;
}

/* latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  src: url('../fonts/jost/Jost-Latin.woff2');
  font-weight: 700;
  font-display: swap;
}
