.accordion {
  &__plus-minus-sign {
    .card {
      border-color: $gray-200;

      &-header {
        padding: ($spacer * 0.75) 0;
      }

      h2 {
        .btn-link {
          .description-and-detail & {
            font-weight: $font-weight-normal;
          }
        }
      }

      .btn {
        width: 100%;
        border: 0;
        font-size: rem-calc(14);
        line-height: rem-calc(24);

        &::after,
        &::before {
          position: absolute;
          top: 50%;
          right: 0;
          display: block;
          content: "";
          width: rem-calc(14);
          height: rem-calc(1);
          transform: none;
          border: 1px solid $primary;
        }

        &::before {
          top: calc(50% - 6px);
          right: rem-calc(6);
          width: rem-calc(1);
          height: rem-calc(14);
          opacity: 0;
          visibility: hidden;
          transition: 250ms ease;
        }

        &.collapsed {
          &::before {
            opacity: 1;
            visibility: visible;
          }

          &::after {
            transform: none;
          }
        }

        &-link {
          margin-bottom: 0;

          @include hover-focus-active() {
            text-decoration: none;
          }
        }
      }

      &-body {
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__arrow-sign {
    .card {
      border-color: $gray-200;

      &-header {
        padding: ($spacer * 0.75) 0;
      }

      h2 {
        .btn-link {
          .description-and-detail & {
            font-weight: $font-weight-normal;
          }
        }
      }

      .btn {
        width: 100%;
        border: 0;
        font-size: rem-calc(14);
        line-height: rem-calc(24);

        .isicon--arrow-down-black {
          transition: all 250ms ease-in-out;
        }

        &:not(.collapsed) {
          .isicon--arrow-down-black {
            transform: rotate(-180deg);
          }
        }

        &-link {
          margin-bottom: 0;

          @include hover-focus-active() {
            text-decoration: none;
          }
        }
      }

      &-body {
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
