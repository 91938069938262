.loader {
  transition: opacity $transition-duration $transition-timing 150ms;
  opacity: 1;

  &.is-loading {
    opacity: 0;
  }

  &.has-loaded {
    display: block !important;

    &img {
      display: inline !important;
    }
  }
}
