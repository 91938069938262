/// Generate positive paddings or negative margins
/// @param {map} $gutters a map with the gutter for each breakpoint
/// @param {map} $breakpoints the breakpoints used by the grid
/// @param {string} $property the property to affect, either padding or margin
@mixin responsive-gutters($gutters: $grid-gutter-widths, $breakpoints: $grid-breakpoints, $property: padding) {
  $gutter-multiplier: 1;

  @if ($property == margin) {
    $gutter-multiplier: -1;
  }

  @each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      $responsive-gutter: map-get($map: $gutters, $key: $breakpoint);

      @if $responsive-gutter != null {
        #{$property}-left: $responsive-gutter / 2 * $gutter-multiplier;
        #{$property}-right: $responsive-gutter / 2 * $gutter-multiplier;
      }
    }
  }
}
