.isicon {
  display: inline-block;

  svg {
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  &--wishlist {
    fill: transparent;
    transition: fill $transition-duration $transition-timing;

    .active & {
      fill: $primary;
    }
  }
}

@include media-breakpoint-down(md) {
  .carousel-controller {
    display: none;
  }
}

