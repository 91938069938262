@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include small;

    color: $value;
    background: adjust-color($color: $value, $alpha: -0.95);
    border-color: adjust-color($color: $value, $alpha: -0.5);
    border-radius: 4px;

    hr {
      border-top-color: darken(theme-color-level($color, $alert-border-level), 5%);
    }

    .alert-link {
      color: darken(theme-color-level($color, $alert-color-level), 10%);
    }
  }

  .prefooter {
    .alert-#{$color} {
      padding: $spacer * 0.25;
      border: 1px solid rgba($color: $value, $alpha: 0.7);

      @include border-radius(0);
    }
  }

  .modal {
    .alert-#{$color} {
      padding: 0;
      background-color: transparent;
    }

    .alert-danger {
      color: adjust-color($color: $value, $red: 35);
    }
  }
}
