.card {
  &--dropdown {
    border: none;
    position: relative;

    .card-header {
      padding: rem-calc(24 0 26);
      border: none;
      border-top: $default-border;
      background: $white;

      @include media-breakpoint-up(lg) {
        border-top: none;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
          content: none !important;
        }
      }

      .card-chevron {
        transform: rotate(90deg);
        margin: 0;
      }

      button {
        padding: 0;
        margin: 0;

        @extend .small;

        font-family: $font-family-sans-serif;
        font-weight: $font-weight-bold;
        line-height: 1;
      }
    }

    &.active {
      .card-chevron {
        transform: rotate(-90deg);
      }
    }

    .card-body {
      padding: rem-calc(0 0);
      background: $white;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }
    }
  }
}

.card-wishlist {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
