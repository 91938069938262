.progress {
  font-size: rem-calc(10);
  height: rem-calc(10);
  background: #f1f1f1;
  border-right: 2px solid $black;
  border-left: 2px solid $black;

  &-container {
    label {
      text-transform: none;
    }

    .btn {
      &-link {
        &--secondary {
          font-size: rem-calc(14);
          line-height: rem-calc(24);
        }
      }

      &.strong {
        font-weight: $font-weight-bold;
      }
    }
  }
}
