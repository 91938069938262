.modal {
  &.alert-country-selector__modal {
    padding: 0 rem-calc(17);

    .modal-dialog {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      width: calc(100% - 34px);
      height: auto;
    }

    .modal-content {
      background: $black;
    }

    .modal-header {
      border: none;
      padding: rem-calc(40) rem-calc(20) 0;
    }

    .modal-body {
      padding: rem-calc(40) rem-calc(20);
    }

    .modal-title {
      width: 100%;
      font-weight: $font-weight-bold;
      text-align: center;
      text-transform: none;
      color: $white;
      font-size: rem-calc(22);
      line-height: rem-calc(29);
    }

    .modal-text {
      color: $white;
      text-align: center;
      margin-bottom: 0;
    }

    .modal-buttons {
      text-align: center;
      margin-top: rem-calc(30);
    }

    .btn {
      text-transform: none;
      margin-bottom: rem-calc(30);
    }

    .link-underline {
      text-decoration: underline;
      text-transform: none;
      color: $white;
    }
  }
}
