.pagination {
  .page-link {
    &:not(.btn-arrow) {
      padding: 0 $spacer * 0.25;
      margin: 0;
      border: 0;

      @include hover-focus-active {
        background-color: transparent;
      }

      &.active {
        text-decoration: underline;
      }
    }

    &.disabled,
    &:disabled {
      color: $gray-200;
      background-color: $white;
    }
  }
}
