// Allow to toggle a compact version of the grid by adding/removing the compact modifier
.toggleable-row {
  opacity: 1;
  transition: opacity $transition-duration $transition-timing;

  @include make-row();

  .toggleable-col {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    .product {
      &--grid-tile {
        @include regular(md);
      }
    }
  }

  &--compact {
    .toggleable-col {
      @include make-col-ready();
      @include make-col(6);

      @include media-breakpoint-up(sm) {
        @include make-col(4);
      }

      .product {
        &--grid-tile {
          @include media-breakpoint-down(sm) {
            @include regular();
          }
        }
      }
    }
  }

  &.fadeOut {
    opacity: 0;
  }
}

.container {
  &.container-fluid {
    max-width: none;
  }
}

// Scrollable rows
.row {
  &.scroll-xs {
    @include media-breakpoint-down(xs) {
      @include scrollable();

      .col-5-5 {
        @include make-col-ready();
        @include make-col(5.5);
      }
    }
  }

  &.scroll-sm-only {
    @include media-breakpoint-only(sm) {
      @include scrollable();

      .col-8-5 {
        @include make-col-ready();
        @include make-col(8.5);
      }
    }
  }

  &.scroll-sm {
    @include media-breakpoint-up(sm) {
      @include scrollable();

      .col-4-5 {
        @include make-col-ready();
        @include make-col(4.5);
      }
    }
  }

  .col-sm-5-5 {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(5.5);
    }
  }

  .col-md-3-5 {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(3.5);
    }
  }

  .col-md-4-5 {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(4.5);
    }
  }

  .col-md-7-5 {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(7.5);
    }
  }

  &.scroll {
    @include scrollable();

    .col-7-5 {
      @include make-col-ready();
      @include make-col(7.5);
    }

    .col-md-6-5 {
      @include make-col-ready();

      @include media-breakpoint-up(md) {
        @include make-col(5.5);
      }
    }
  }
}
