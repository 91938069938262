.veil {
  position: absolute;
  z-index: 100;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: $spinner-background;
  }
}
