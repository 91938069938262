img {
  transition: opacity $transition-duration $transition-timing;

  &.lazyload,
  &.lazyloading,
  &.lazyloaded {
    width: 100%;
  }

  &.lazyloading {
    opacity: 0 !important;
  }

  &.lazyloaded {
    opacity: 1;
  }
}

.container-lazyload,
.lazyload-container {
  background-color: $gray-100;
}
