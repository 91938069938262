@import "../experience/components/mixins";

.region-istogram {
  text-align: center;
  margin: 0 0.2rem 0 0.4rem;

  @include media-breakpoint-up(sm) {
    margin: 0 1rem;
  }

  .container-myChart {
    margin: 3.5rem 0;
    position: relative;
    max-height: 100%;

    @include media-breakpoint-up(sm) {
      height: 40vh;
      margin-bottom: 2.5rem;
    }

    display: flex;
    justify-content: center;
  }

  .istogram-title {
    font-size: 55px;
    font-weight: 300;
    line-height: 60px;
    margin-bottom: 3rem;
    margin-top: 2rem;

    @include media-breakpoint-up(sm) {
      font-size: 130px;
      line-height: 120px;
      margin-bottom: 4.3rem;
    }
  }

  .istogram-subtitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 1rem;
    letter-spacing: 0.09px;
    text-transform: uppercase;
  }

  .istogram-paragraph {
    font-size: 14px;
    line-height: 20px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 3.5rem;
    flex-wrap: wrap;
    padding: 0 1rem;

    .btn-primary-istogram,
    .btn-secondary-istogram {
      border: 1px solid #000;
      font-weight: 500;
      padding: 0.9rem;
      font-size: 12px;
      line-height: 18px;

      @include media-breakpoint-down(sm) {
        min-width: auto;
        padding: 0.7rem 2.7rem;
      }
    }

    .btn-primary-istogram {
      color: #fff;
      background-color: #000;
    }

    .btn-secondary-istogram {
      color: #212529;
      background-color: #fff;
    }
  }
}
