// Root menu styles for desktop and mobile devices
.menu--root {
  // transition: margin-left $transition-duration $transition-timing;

  @include media-breakpoint-up(lg) {
    margin-left: $spacer * 0.75;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    @include no-scrollbar();
  }

  .nav-item {
    @extend %root-nav-item;
  }

  .nav-link {
    position: relative;

    @include media-breakpoint-down(md) {
      padding: 0;
      font-size: rem-calc(14);
      line-height: rem-calc(18);
      font-weight: $font-weight-bold;
    }

    @include media-breakpoint-up(lg) {
      padding: rem-calc(17) 0;

      .sticky & {
        padding: rem-calc(16) 0;
      }

      &.active {
        border-bottom: 1px solid $black;
        z-index: 2;
      }
    }

    @include media-breakpoint-up(xl) {
      padding: rem-calc(33) 0;

      .sticky & {
        padding: rem-calc(11) 0;
      }
    }
  }
}
