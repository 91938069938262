// Vertical menu styles for x-small devices
.nav {
  &--vertical {
    display: none;
    position: fixed;
    left: 100%;
    width: 100%;
    bottom: 0;
    top: 0;
    background: $white;
    transition: left $transition-duration $transition-timing;
    z-index: 1;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    // XS devices only
    @include media-breakpoint-up(lg) {
      display: none !important;
    }

    &.show {
      display: block;
      left: 0;
    }

    &__rootmenu {
      .menu--root {
        justify-content: space-between;
        padding: rem-calc(18) 0 rem-calc(40);
        width: 100%;

        .nav-item {
          padding: 0;

          .nav-link {
            border-bottom: 1px solid $white;
            color: #afafaf;

            &.active {
              border-bottom-color: $black;
              color: $black;
            }
          }
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: rem-calc(map-get($navbar-heights, xs));
      padding: 0 rem-calc(map-get($grid-gutter-widths, xs));

      @include media-breakpoint-down(md) {
        padding-right: 0;
      }

      &__root {
        display: none;

        [data-level="2"] & {
          display: block;
          height: 100%;
          max-width: calc(100% - #{rem-calc(50)});
        }
      }

      &__close {
        height: 100%;
        width: rem-calc(50);
        line-height: rem-calc(50);
        text-align: center;
      }

      &__logo {
        @include logo();
      }
    }

    &__container {
      @include media-breakpoint-down(md) {
        flex: 1 0;

        & > .row {
          &,
          & > .col {
            height: 100%;
          }
        }
      }
    }

    &__menu-wrapper {
      position: relative;
      overflow-x: hidden;
      overflow-y: scroll;

      @include media-breakpoint-down(md) {
        height: 95%;
      }
    }

    .submenu {
      display: none;
      position: absolute;
      left: 100%;
      top: 0;
      width: 100%;
      flex-direction: column;

      .navbar-back,
      .nav-link--view-all {
        display: none;
      }

      .submenu {
        .navbar-back,
        .nav-link--view-all {
          display: block;

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    // Nav footer
    &__footer {
      padding: 0 rem-calc(map-get($grid-gutter-widths, xs));

      &__link {
        display: block;
        font-size: rem-calc(14);
        line-height: rem-calc(18);
        padding: rem-calc(16) 0;

        .isicon {
          margin-right: $spacer * 0.75;
        }
      }
    }
  }

  .menu {
    &--vertical {
      transform: translateX(0%);
      transition: transform $transition-duration $transition-timing;
      display: flex;
      justify-content: space-between;

      > .nav-item {
        > .nav-link {
          display: none;
        }
      }
    }
  }

  &[data-level="2"] {
    .menu {
      &--vertical {
        transform: translateX(-100%);
      }
    }
  }

  &[data-level="3"] {
    .menu {
      &--vertical {
        transform: translateX(-200%);
      }
    }

    /* THIRD LEVEL FOOTER
    .nav--vertical__footer {
      display: none;
    }
     */
  }
}
