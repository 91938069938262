.threeImagesSlide {
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .image3Slide-tiles {
    min-width: 700px;

    img {
      object-fit: cover;
      min-width: 280px;

      &.r1 {
        aspect-ratio: 1;
      }

      &.r4x5 {
        aspect-ratio: 4/5;
      }
    }
  }
}
