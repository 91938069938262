.minicart,
.miniwishlist,
.miniaccount {
  &--wrapper {
    .product-info {
      margin-top: rem-calc(6);
      margin-bottom: rem-calc(6);
    }

    .myaccount-miniaccount {
      .col {
        @include make-col-ready();

        flex: 0 0 100%;
        max-width: 100%;
      }

      .btn {
        width: 100%;

        &-primary {
          margin-top: 0 !important;
        }

        &-link {
          &--secondary {
            text-align: left;
          }
        }
      }

      p {
        &:not(.small) {
          font-size: rem-calc(18);
          line-height: rem-calc(28);
        }
      }

      .alert {
        margin-bottom: 0;
        margin-top: 1rem;
      }

      .login {
        padding-bottom: rem-calc(65);
      }
    }
  }

  #AmazonPayButtonMiniCart {
    width: 100% !important;
    height: 51px !important;
    position: relative !important;
  }

  .amazon-container {
    .amazon-overlay {
      display: flex;
      flex-direction: row;
      flex: 1 0 100%;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      pointer-events: none;
      background-color: $white;
      border: 1px solid $black;

      img {
        width: 100%;
        height: 75%;
        transform: translateY(5px);
        object-fit: contain;

        @include media-breakpoint-down(md) {
          height: 65%;
          transform: translateY(3px);
        }
      }
    }

    height: rem-calc(52);

    @include media-breakpoint-down(md) {
      height: rem-calc(34);
      overflow: hidden;
    }
  }
}

.miniwishlist {
  &--wrapper {
    .wishlist-product-card__size-selector__toggler {
      display: none;
    }

    .product-line-item {
      .line-item-attributes {
        display: none;

        &.size {
          display: none;
        }
      }
    }

    .wishlist-product-card__size-selector {
      margin-top: 1.4rem;
    }
  }
}

.panel--minicart {
  .icon-betterdenim {
    bottom: auto;
    right: auto;
  }
}
