@mixin button-animation(
  $background: $primary,
  $hover-background: $secondary,
  $duration: 300ms,
  $timing: ease
) {
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0%;
    transition: width $duration $timing;
    background-color: $background;
    z-index: -1;
  }

  @include hover {
    background-color: $hover-background;

    &::before {
      width: 100%;
    }
  }
}

@mixin button-flash-text(
  $name,
  $flash-base-color,
  $flash-color,
  $duration: 300ms,
  $timing: ease
) {
  animation: flashText-#{$name} $duration $timing forwards;

  @keyframes flashText-#{$name} {
    from {
      color: $flash-base-color;
    }

    50% {
      color: $flash-color;
    }

    to {
      color: $flash-base-color;
    }
  }
}

@mixin button-icon-animation($delay: 0ms) {
  animation: swingIcon 750ms ease-in-out $delay;

  @keyframes swingIcon {
    from {
      transform: translate(0, -50%);
    }

    50% {
      transform: translate(-35%, -50%);
    }

    to {
      transform: translate(0, -50%);
    }
  }
}

@mixin logo {
  a,
  img {
    display: block;
    width: 100%;
    max-width: rem-calc(100);

    @include media-breakpoint-up(lg) {
      margin: auto;
      max-width: rem-calc(95);
    }

    @include media-breakpoint-up(xl) {
      max-width: rem-calc(138);
    }
    // transition: all $transition-duration $transition-timing;
  }
}

@mixin faux-border($faux-border-width: 300%, $faux-border-color: #ccc) {
  content: "";
  position: absolute;
  bottom: 0;
  left: auto;
  height: 1px; // pixels as decorative element, does not need to scale with text
  width: $faux-border-width;
  background-color: $faux-border-color;
}

@mixin small {
  font-size: rem-calc(12);
  line-height: rem-calc(20);
  letter-spacing: rem-calc(1);

  &.strong {
    letter-spacing: rem-calc(0.5);
  }

  a {
    letter-spacing: rem-calc(0.5);
  }
}

@mixin smaller {
  font-size: rem-calc(10);
  line-height: rem-calc(19);
  letter-spacing: rem-calc(1);
}

@mixin show {
  opacity: 1;
  visibility: visible;
}

@mixin hide {
  opacity: 0;
  visibility: hidden;
}

@mixin videoVolumeButton {
  .video-volume {
    position: absolute;
    bottom: rem-calc(20);
    right: 20px;
    z-index: 2;
    outline: none;

    @include media-breakpoint-up(md) {
      bottom: rem-calc(54);
    }

    .isicon {
      padding-bottom: 15px;
      border-radius: 50%;
      background: rgba($black, 0.5);

      &--volume-up-fill {
        display: none;
        z-index: 2;
      }

      &--volume-mute-fill {
        display: block;
        z-index: 2;
      }
    }
  }
}
