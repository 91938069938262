.paypal-container {
  // PDP

  /* .paypal-pdp-button,
    // Cart and MiniCart
  .paypal-cart-button,
    // Checkout Billing
  .paypal-checkout-ba-button,
  .paypal-checkout-button {
    // Hide PayPal cart button, but keep it clickable

  } */
  .paypal-content {
    //Fix firefox #3363
    opacity: 0.01;
    cursor: pointer;
    z-index: 3;
  }

  #cart-paypal-button-container {
    .paypal-button-logged {
      height: 51px;
      vertical-align: top;
      min-height: 51px;
      max-height: 51px;

      .fake-button-paypal {
        border-radius: 3px;
        background: #ffc439;

        .paypal-logo {
          height: 24px;
        }
      }
    }
  }

  &_overlay {
    display: flex;
    flex-direction: row;
    flex: 1 0 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    pointer-events: none;
    background-color: $white;
    border: 1px solid $black;

    img {
      width: 100%;
      height: 80%;
      object-fit: contain;
    }
  }

  height: rem-calc(52); // Same height of a CTA

  @include media-breakpoint-down(md) {
    height: rem-calc(34);
    overflow: hidden;
  }
}
// moved paypal.css in global
@keyframes load {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

#cart-paypal-button-container {
  position: relative;
  text-align: center;
  margin-bottom: 1em;
  z-index: 1;
}

#billing-paypal-button-container {
  position: relative;
}

.paypal-loader-container {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.7;
  z-index: 990;
}

.paypal-loader {
  position: absolute;
  color: #383838;
  font-size: 0.3em;
  background: #fff;
  opacity: 0.5;
  margin: auto;
  left: 50%;
  top: 50%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  text-indent: -9999em;
  -webkit-animation: load 1s infinite linear;
  animation: load 1s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.add-paypal-account {
  color: #0070d2;
  cursor: pointer;
  text-decoration: underline;
}

.remove-paypal-button {
  border: none;
  background: transparent;
  color: #0070d2;
}

.paypal-account-button {
  padding-left: 1.25rem;
  padding-right: 10rem;
}

