// Horizontal menu styles for tablet and larger devices
$submenu-sm-gutter: map-get($grid-gutter-widths, sm);
$submenu-md-gutter: map-get($grid-gutter-widths, md);
$submenu-transition-duration: 300ms;
$submenu-transition-timing: ease;

.nav {
  &--horizontal {
    z-index: 1;
    position: absolute;
    background: $white;
    left: 0;
    right: 0;
    width: 100%;
    top: rem-calc(map-get($navbar-heights, sm));
    border-bottom: $default-border;
    opacity: 0;
    // transition: opacity $submenu-transition-duration $submenu-transition-timing, top $transition-duration $transition-timing;

    @include media-breakpoint-up(lg) {
      top: rem-calc(map-get($navbar-heights, sm));
      border-bottom: none;
      opacity: 1;
      height: 0;
    }

    @include media-breakpoint-up(xl) {
      top: rem-calc(map-get($navbar-heights, md));
    }

    @include media-breakpoint-down(md) {
      .menu--horizontal {
        display: none;
      }
    }

    .menu--horizontal {
      height: 0;

      .nav-item {
        display: block;
        width: 100%;
      }
    }

    &.hiding {
      opacity: 0;
    }

    // Hide for xs devices
    @include media-breakpoint-down(md) {
      display: none !important;
    }

    // Root-level menu
    .nav-item {
      @extend %root-nav-item;

      .nav-link {
        padding: rem-calc(19) 0 rem-calc(18);
        position: relative;

        .extra-label {
          color: $black;
          font-weight: $font-weight-bolder;
          font-family: $headings-font-family;
          text-transform: none;
          vertical-align: super;
        }

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    // Level-2 submenu
    .submenu {
      display: none;
      position: absolute;
      background: $white;
      left: rem-calc(map-get($grid-gutter-widths, sm) / 2);
      top: rem-calc(map-get($navbar-heights, sm));
      z-index: 1;
      width: 33.33333%;
      padding: 0 0 rem-calc(40);

      @include media-breakpoint-up(lg) {
        width: 100%;
        top: 0;
        left: rem-calc(map-get($grid-gutter-widths, lg) / 2);
        position: relative;

        &::before {
          content: " ";
          width: 300%;
          left: -150%;
          right: 0;
          position: absolute;
          height: 100%;
          background: $white;
        }
      }

      .nav-item {
        display: block;
        margin-right: 0;
        padding-right: $submenu-sm-gutter;

        @include media-breakpoint-up(lg) {
          padding-right: rem-calc(20);
          margin-right: auto;
          flex: 1;

          &:last-child {
            padding-right: 0;
          }
        }

        &--2-column {
          @include media-breakpoint-up(lg) {
            flex: 2;
          }
        }

        .nav-link {
          display: block;
          padding: $spacer * 0.75 0;
          position: relative;
          border: none;
          font-size: rem-calc(14);
          line-height: rem-calc(18);
          letter-spacing: rem-calc(1);
          text-transform: uppercase;

          @include media-breakpoint-up(lg) {
            padding: $spacer * 0.5 0;
            font-size: rem-calc(16);
            line-height: rem-calc(25);
            // font-size: rem-calc(22); // Misure XD
            // line-height: rem-calc(44); // Misure XD
          }

          &.active:not(.nav-link--view-all) {
            // Cover the submenu left border
            border-right: 1px solid $white;
            margin-right: -1px;
            z-index: 2;

            @include media-breakpoint-up(lg) {
              border: none;
              margin: 0;
            }
          }

          .cat-with-extra-label {
            @include hover-focus {
              text-decoration: underline;
            }
          }
        }

        // Level-3 submenu
        .submenu {
          left: calc(100% - #{rem-calc($submenu-sm-gutter)});
          top: 0;
          width: 200%;
          padding: $spacer * 0.75 0;
          border-left: $default-border;
          height: rem-calc(500); // recalc with JS

          @include media-breakpoint-up(lg) {
            position: relative;
            display: flex !important;
            height: auto;
            width: calc(100% - #{rem-calc($submenu-md-gutter)});
            left: 0;
            border: none;
            padding: 0;
            flex-direction: column;

            @include hide();

            animation: menuFadeIn $submenu-transition-duration $submenu-transition-timing forwards;

            &.prepend {
              padding-bottom: 10px;
            }

            &:not(.prepend) {
              min-height: rem-calc(320);
            }

            &::before {
              content: none;
            }
          }

          .nav-item {
            padding-right: 0;

            @include media-breakpoint-up(lg) {
              display: block;
              flex: none;
            }

            .nav-link {
              border: none;
              padding: $spacer * 0.375 $spacer * 0.75;
              font-size: rem-calc(13);
              line-height: rem-calc(17);
              font-weight: $font-weight-semibold;
              letter-spacing: rem-calc(1);
              text-transform: none;

              .cat-with-extra-label {
                @include hover-focus {
                  text-decoration: underline;
                }
              }

              @include media-breakpoint-up(lg) {
                padding: $spacer * 0.25 0;
                font-size: rem-calc(14);
                line-height: rem-calc(19);
                // font-size: rem-calc(18); // Misure XD
                // line-height: rem-calc(40); // Misure XD

                .cat-with-extra-label:has(> .extra-label) {
                  white-space: nowrap;
                }
              }

              @include media-breakpoint-up(xl) {
                font-size: rem-calc(15);
                line-height: rem-calc(21);
                // font-size: rem-calc(18); // Misure XD
                // line-height: rem-calc(40); // Misure XD
              }

              // Disable aux border for all states
              &::after {
                content: none !important;
              }
            }
          }

          &--2-columns {
            padding-top: $spacer * 0.75;

            @include media-breakpoint-up(lg) {
              column-count: 2;
              padding-top: $spacer * 1.25;
              padding-right: 66.6667%;
            }

            .nav-item {
              @include media-breakpoint-up(lg) {
                display: inline-block;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

// Animations
@keyframes menuFadeIn {
  to {
    @include show();
  }
}
