.accordion {
  .card-header {
    .btn {
      display: flex;
      position: relative;
      cursor: pointer;
      user-select: none;

      &::after {
        @include media-breakpoint-down(md) {
          content: "";
          position: absolute;
          top: calc(50% - 3px);
          right: 0;

          @include arrow($spacer * 0.5, $spacer * 0.5, $white, rem-calc(1px), up);
        }
      }

      &.collapsed {
        &::after {
          @include media-breakpoint-down(md) {
            transform: translateY(-5px) rotate(45deg);
          }
        }
      }
    }
  }
}
