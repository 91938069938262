.site-search {
  &.panel {
    top: 0;
    width: 100%;
    right: 0;
    left: 0;
    height: 100%;

    @include media-breakpoint-up(sm) {
      height: auto;
      bottom: auto;
    }

    form {
      overflow: hidden;

      @include media-breakpoint-up(sm) {
        overflow-y: auto;
        height: auto;
      }

      &.d-flex {
        @include media-breakpoint-down(xs) {
          position: absolute;
          width: 100%;
        }
      }
    }
  }

  &.hiding {
    top: -100%;
    right: 0;
  }

  form {
    overflow: hidden;

    p {
      a {
        text-decoration: none;

        @include hover-focus-active {
          text-decoration: none;
          border-bottom: 1px solid;
        }
      }
    }

    &.d-flex {
      @include media-breakpoint-down(xs) {
        position: relative;
        height: 100%;
        padding-bottom: rem-calc(50);
      }
    }
  }

  .search-border-bottom {
    border-bottom: 1px solid $gray-700;
  }

  &__container {
    &.container {
      padding-left: 0 !important;
      padding-right: 0 !important;

      @include media-breakpoint-up(xl) {
        padding-left: $spacer * 0.8 !important;
        padding-right: $spacer * 0.8 !important;
      }
    }

    .btn {
      width: auto;
      min-width: auto;
      height: $spacer * 2.5;

      @include media-breakpoint-up(md) {
        height: $spacer * 3;
      }

      @include hover-focus-active {
        box-shadow: none !important;
      }
    }
  }

  .cross {
    @include cross($spacer * 0.9, $spacer * 0.1, $spacer * 2.5, $primary);

    @include media-breakpoint-up(md) {
      @include cross($spacer * 0.9, $spacer * 0.1, $spacer * 3, $primary);
    }
  }

  .input-group-prepend {
    align-items: center;

    @include media-breakpoint-up(lg) {
      align-items: flex-end;
    }

    .btn {
      min-height: auto;
      height: max-content;
    }

    .isicon--search {
      @include media-breakpoint-up(lg) {
        width: 25px !important;
        height: 25px !important;
      }

      @include media-breakpoint-up(xl) {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }

  .form {
    &-control {
      height: auto;
      line-height: 100%;

      &,
      &::placeholder {
        color: $primary;
        font-size: rem-calc(35);

        @include media-breakpoint-up(lg) {
          font-size: rem-calc(40);
        }
      }
    }
  }

  &--menu {
    margin-bottom: rem-calc(36);

    .btn {
      width: auto;
      min-width: auto;
    }

    .search-field__container {
      border: 0;
      border-bottom: 1px solid $black;
    }
  }

  .size-toggler {
    display: none !important;
  }
}

body {
  & > .page {
    &.panel-open {
      &.searchbar {
        overflow: hidden;

        &::after {
          z-index: 2;
        }
      }
    }
  }
}
