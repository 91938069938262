.squareSlider {
  z-index: 0;

  .carousel-item {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: $gray-100;
    height: 0;
    padding-top: 100%;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;

    @include media-breakpoint-up(lg) {
      padding-top: 93%;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 100%;
    }
  }

  .carousel-indicators {
    position: absolute;
    left: 22px;
    right: auto;
    bottom: 40px !important;

    @include media-breakpoint-up(lg) {
      left: auto;
      right: 55px;
      bottom: 35px !important;
    }

    button {
      display: inline-block;

      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }

    button.squareSlider__indicator {
      height: 3px;
      background-color: $gray-600;
      margin: 0 0 0 15px;
      cursor: pointer;
      border: none;
      padding: 0;

      @include media-breakpoint-up(lg) {
        margin: 0 15px 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    button.active {
      background-color: $white;
    }
  }

  #scrollButton {
    color: white;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22Livello_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2052.54%2053.55%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bisolation%3Aisolate%3Bopacity%3A.75%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22arrow_left_desk%22%3E%3Ccircle%20id%3D%22Ellisse_1%22%20class%3D%22cls-1%22%20cx%3D%2226.27%22%20cy%3D%2226.77%22%20r%3D%2224%22%2F%3E%3Cg%20id%3D%22Tracciato_123%22%3E%3Cpolygon%20points%3D%2226.27%2032.07%2018.3%2022.78%2019.82%2021.48%2026.27%2029%2032.73%2021.48%2034.24%2022.78%2026.27%2032.07%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    position: absolute;
    bottom: 10px;
    width: 20px;
    height: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      bottom: 6%;
      width: 48px;
      height: 48px;
    }
  }
}
