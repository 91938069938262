// Reset regular buttons
button {
  &:not(.btn) {
    background: transparent;
    border: none;
    padding: 0;
    color: $body-color;
  }
}

// Extend Bootstrap buttons
.btn {
  position: relative;
  letter-spacing: rem-calc(0.5);
  min-width: rem-calc(190);
  margin-bottom: $spacer * 0.5;
  -webkit-appearance: none;

  @include media-breakpoint-up(md) {
    min-width: rem-calc(220);
  }

  &-primary {
    min-height: rem-calc(50);

    &:not(.no-hover):not(:disabled):not(.disabled) {
      @include button-animation($gray-900, $primary, 350ms, $btn-transition-timing);

      @include hover {
        @include button-flash-text('primary', $white, $gray-900, 350ms, $btn-transition-timing);

        border-color: $gray-900;
      }

      &.leaving {
        @include button-flash-text('primary', $white, $gray-900, 350ms, $btn-transition-timing);
      }
    }

    &--arrow {
      border-color: $gray-200;
    }
  }

  &-secondary {
    min-height: rem-calc(50);

    @include button-variant($white, $primary, $white, $gray-900, $white, $gray-900);

    &:not(.no-hover):not(:disabled):not(.disabled) {
      @include hover {
        @include button-flash-text('secondary', $gray-900, $white, 350ms, $btn-transition-timing);
      }

      &.leaving {
        @include button-flash-text('secondary', $gray-900, $white, 350ms, $btn-transition-timing);
      }
    }
  }

  &-block {
    min-width: 0;
    max-width: 100%;
    width: 100%;
  }

  &-xs-block {
    @include media-breakpoint-down(xs) {
      min-width: 0;
      max-width: 100%;
      width: 100%;
    }
  }

  &-auto {
    min-width: 0;
    max-width: auto;
  }

  &-xs-auto {
    @include media-breakpoint-down(xs) {
      min-width: 0;
      max-width: auto;
    }
  }

  &-link {
    min-width: 0;
    padding: 0;
    display: inline-block;

    &--primary {
      font-size: rem-calc(13);
      letter-spacing: rem-calc(0.5);
      font-weight: $font-weight-semibold;
      line-height: 1.6;
      color: $primary;
      text-decoration: none;
      border-bottom: 1px solid $primary;

      @include hover {
        text-decoration: none;
        border-bottom: 1px solid $gray-700;
        color: $gray-700;
      }
    }

    &--secondary {
      font-size: rem-calc(12);
      letter-spacing: 0;
      color: $gray-700;
      font-family: $font-family-sans-serif;
      font-weight: $font-weight-normal;
      border-bottom: none;
      text-decoration: underline;

      @include hover {
        border-bottom: none;
        text-decoration: underline;
        color: $primary;
      }
    }

    h1 &,
    h2 &,
    h3 & {
      font-weight: $font-weight-light;
    }
  }

  &-editorial {
    padding-right: $btn-padding-x * 3.5;
    min-width: rem-calc(195);
    text-align: left;

    @include button-animation($primary, transparent, $btn-transition-duration, $btn-transition-timing);

    span:not(.btn-icon) {
      position: relative;
    }

    .btn-icon {
      transition: none;
    }

    @include hover {
      background-color: transparent;

      .btn-icon {
        transition: $btn-transition-duration $btn-transition-timing 150ms;

        @include button-icon-animation($delay: 10ms);
      }
    }

    &.leaving {
      .btn-icon {
        @include button-icon-animation($delay: 10ms);
      }
    }

    // No-Border modifier
    &--no-border {
      border-color: $white;

      @include hover {
        border-color: transparent;
      }
    }

    &--arrow {
      min-width: rem-calc(50);
      height: rem-calc(50);
      padding: 0;
    }
  }

  &-icon {
    position: absolute;
    right: $btn-padding-x;
    top: 50%;
    transform: translateY(-50%);

    &.left {
      left: $btn-padding-x;
      right: auto;

      svg {
        transform: rotate(180deg);
      }
    }

    &--arrow {
      &,
      &.left {
        left: calc(50% - 12px);
      }
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: 1;
    background: $gray-200;
    border-color: $gray-200;
    color: $gray-700;
    animation: none !important;

    &.btn-link {
      background: transparent;
      border-color: transparent;
      color: $gray-400;
      border-bottom: $gray-400;
    }
  }

  &-arrow {
    margin-bottom: 0;

    &:disabled {
      color: $gray-200;
      background-color: $white;
    }
  }

  &-square {
    min-width: 0;
    width: rem-calc(50);
    height: rem-calc(50);
    line-height: rem-calc(50);
    padding: 0;

    &--size {
      [data-attr=size] & {
        width: rem-calc(39);
        height: rem-calc(39);
        border: 1px solid black;
        border-radius: 0;
        padding: 10px;
        margin-right: rem-calc(10) !important;
        margin-bottom: rem-calc(10) !important;
      }
    }
  }

  &-circle {
    @extend .small;

    font-family: "Jost", "Helvetica Neue", Helvetica, Arial, sans-serif;
    min-width: 0;
    font-size: rem-calc(14) !important;
    width: rem-calc(39);
    height: rem-calc(39);
    line-height: rem-calc(39);
    padding: 0;
    text-align: center;
    border: 1.5px solid $primary; // in px as decorative
    background: transparent;
    border-radius: 50%;
    position: relative;

    &:disabled,
    &.disabled {
      background: transparent;
      border-color: transparent;
      color: $primary;

      // Slanted strikethrough
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 49%;
        left: 15%;
        width: 70%;
        height: 1px; // using px as decorative
        transform: rotate(-27deg);
        background: $gray-400;
      }
    }

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(16) !important;
      width: rem-calc(34);
      height: rem-calc(34);
      line-height: rem-calc(34);
    }

    .swatch {
      display: inline-block;
      vertical-align: text-bottom;
      width: 19px;
      height: 19px;
      line-height: 19px;
      padding: 0;
      text-align: center;
      border: none;
      border-radius: 50%;
      background-size: cover;
    }
  }

  &-flash {
    display: none;
    opacity: 0;
    animation: flahsFadeIn $btn-transition-timing $transition-duration forwards;
  }

  &-flashable {
    opacity: 1;
    transition: opacity $btn-transition-timing $transition-duration;

    &.hiding {
      opacity: 0;
    }
  }
}

// Non-button links in form's paragraphs should be underlined
a:not(.btn) {
  form p & {
    text-decoration: underline;
  }
}

@keyframes flahsFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
