.hero-slider.new-hero-slider {
  z-index: 0;

  .carousel-inner {
    height: calc(100vh - 88px);

    @include media-breakpoint-up(xl) {
      height: calc(-131px + 100vh);
    }
  }

  .carousel-item {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: $gray-100;
    height: 100%;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;

    .hero-slide {
      position: relative;
    }
  }

  .new-hero-slider__indicators {
    @include media-breakpoint-up(lg) {
      margin-left: 0;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    color: $white;
    opacity: 1;

    @include media-breakpoint-down(md) {
      align-items: flex-end;
      bottom: 35px;
    }
  }

  .carousel-control-next {
    &-icon {
      width: 50px;
      height: 50px;

      @include media-breakpoint-up(lg) {
        width: 90px;
        height: 90px;
      }

      rotate: 180deg;
      background-image: url(escape-svg("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22Livello_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2052.54%2053.55%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22Tracciato_136%22%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%2229.36%2013.87%2030.66%2015.39%2016.55%2027.51%2030.66%2039.64%2029.36%2041.15%2013.48%2027.51%2029.36%2013.87%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"));
    }
  }

  .carousel-control-prev {
    &-icon {
      width: 50px;
      height: 50px;

      @include media-breakpoint-up(lg) {
        width: 90px;
        height: 90px;
      }

      background-image: url(escape-svg("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22Livello_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2052.54%2053.55%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22Tracciato_136%22%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%2218.98%2039.51%2017.68%2038%2031.8%2025.87%2017.68%2013.75%2018.98%2012.23%2034.87%2025.87%2018.98%2039.51%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"));
    }
  }

  .carousel-indicators {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px !important;
    margin-inline: auto;

    @include media-breakpoint-up(lg) {
      margin-inline: 0;
      left: 5%;
      right: 5%;
    }

    button {
      display: inline-block;
      height: 4px;
      background-color: $gray-600;
      margin: 0 15px 0 0;
      cursor: pointer;
      border: none;
      padding: 0;

      &:last-child {
        margin: 0;
      }

      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }
    }

    button.active {
      background-color: $white;
    }
  }

  #scrollButton {
    color: white;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22Livello_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2052.54%2053.55%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bisolation%3Aisolate%3Bopacity%3A.75%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22arrow_left_desk%22%3E%3Ccircle%20id%3D%22Ellisse_1%22%20class%3D%22cls-1%22%20cx%3D%2226.27%22%20cy%3D%2226.77%22%20r%3D%2224%22%2F%3E%3Cg%20id%3D%22Tracciato_123%22%3E%3Cpolygon%20points%3D%2226.27%2032.07%2018.3%2022.78%2019.82%2021.48%2026.27%2029%2032.73%2021.48%2034.24%2022.78%2026.27%2032.07%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    position: absolute;
    bottom: 10px;
    width: 20px;
    height: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      bottom: 6%;
      width: 48px;
      height: 48px;
    }
  }
}

.experience-component.experience-assets-hero_slider:first-child {
  #scrollButton {
    @include media-breakpoint-up(xl) {
      bottom: 12%;
    }
  }
}
