@mixin product-tile-tall-clip-hide {
  clip-path: inset(100% -1px -1px -1px);
}

@mixin product-tile-tall-clip-show {
  clip-path: inset(0% -1px -1px -1px);
}

&--is-tall {
  position: relative;

  .tile-body {
    background-color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    @include product-tile-tall-clip-hide;

    transition: clip-path $transition-duration $transition-timing;
  }

  @include hover-focus-active() {
    .tile-body {
      @include product-tile-tall-clip-show;

      // do not show when animating inside a carousel
      .item.animate & {
        @include product-tile-tall-clip-hide;
      }
    }
  }
}
