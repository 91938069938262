@import "../experience/components/mixins";

.video-component {
  @include media-breakpoint-up(lg) {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: $gray-100;
    height: 0;
    padding-top: (9/16) * 100%;
  }

  &__video {
    height: 100%;
    object-fit: cover;
  }

  &__bar {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .video-component__title {
      font-size: rem-calc(45);
      line-height: 1;

      @include media-breakpoint-up(lg) {
        font-size: rem-calc(200);
      }
    }

    .video-component__CTA {
      a {
        @include widget_cta;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__bar.content-primary {
      color: $white;

      a {
        color: $white;
        border-bottom: 2px solid $white;
      }
    }
  }

  &__container {
    position: relative;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .experience-video__controls__play,
    .video-volume {
      &:active,
      &:focus-visible,
      &:focus {
        border: none;
        outline: none;
      }
    }

    .experience-video__controls__play {
      right: 50px;
      bottom: 20px;

      @include media-breakpoint-up(lg) {
        left: 65px;
        bottom: 80px;
      }

      .isicon--video-play {
        display: block;
        background-image: escape-svg(url("data:image/svg+xml, <svg id='Livello_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52.54 53.55'><defs><style>.cls-1{fill:#fff;}</style></defs><path class='cls-1' d='M15.33,9.34V42.4c9.46-5.52,18.87-11.01,28.32-16.53-9.46-5.52-18.87-11.01-28.32-16.53Z'/></svg>"));
        background-size: cover;
      }

      .isicon--video-pause {
        display: none;
        background-image: escape-svg(url("data:image/svg+xml, <svg id='Livello_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52.54 53.55'><defs><style>.cls-1{fill:#fff;}</style></defs><path class='cls-1' d='M34.46,17.3c0-1.55,.02-3.11-.03-4.66-.04-1.29-.86-2.11-2.14-2.24-1.73-.18-2.8,.87-2.8,2.79,0,9.06,0,18.11,0,27.17,0,.34,.03,.68,.11,1.01,.2,.94,.89,1.58,1.83,1.74,1.82,.31,3.02-.68,3.03-2.57,.02-4.56,0-9.13,0-13.69,0-3.18,0-6.36,0-9.54Z'/><path class='cls-1' d='M22.92,12.11c-.25-.97-.89-1.53-1.88-1.69-1.75-.28-2.95,.72-2.96,2.5-.02,4.61,0,9.23,0,13.84s-.01,9.23,0,13.84c0,1.84,1.37,2.9,3.1,2.47,1.17-.29,1.85-1.28,1.85-2.71,0-9.08,0-18.17,0-27.25,0-.34-.04-.68-.12-1.01Z'/></svg>"));
        background-size: cover;
      }
    }

    .video-volume {
      right: 10px;
      bottom: 20px;

      @include media-breakpoint-up(lg) {
        left: 85px;
        bottom: 80px;
      }

      .isicon--volume-mute-fill__new {
        display: none;
        background-image: escape-svg(url('data:image/svg+xml, <svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.54 53.55"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M25.88,12.96c-.66-.93-1.81-.98-2.76-.2-3.19,2.64-6.38,5.29-9.58,7.92-.16,.13-.41,.22-.63,.23-1.5,.02-2.99,.04-4.49,0-1.15-.03-1.96,.37-2.32,1.51v8.03c.41,1.13,1.25,1.48,2.4,1.44,1.47-.06,2.94-.02,4.41,0,.2,0,.44,.09,.6,.21,.8,.64,1.58,1.3,2.37,1.95,2.42,2.01,4.85,4.02,7.27,6.03,.43,.36,.91,.58,1.48,.5,1.03-.13,1.64-.89,1.64-2.05,0-7.18,0-14.35,0-21.53,0-.94,.04-1.89-.02-2.83-.02-.41-.14-.88-.38-1.2Z"/><polygon class="cls-1" points="44.55 21.77 42.55 19.78 37.52 24.81 32.51 19.8 30.58 21.73 35.59 26.74 30.55 31.78 32.55 33.77 37.58 28.74 42.58 33.74 44.51 31.81 39.51 26.8 44.55 21.77"/></svg>'));
        background-size: cover;
      }

      .isicon--volume-up-fill__new {
        background-image: escape-svg(url('data:image/svg+xml, <svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.54 53.55"><defs><style>.cls-1{fill:#fff;}</style></defs><path class="cls-1" d="M6.11,22.41c.36-1.14,1.17-1.54,2.32-1.51,1.49,.04,2.99,.02,4.49,0,.21,0,.46-.09,.63-.23,3.2-2.63,6.39-5.28,9.58-7.92,.95-.78,2.1-.73,2.76,.2,.23,.33,.35,.79,.38,1.2,.05,.94,.02,1.89,.02,2.83,0,7.18,0,14.35,0,21.53,0,1.16-.61,1.92-1.64,2.05-.57,.07-1.05-.15-1.48-.5-2.42-2.01-4.85-4.02-7.27-6.03-.79-.65-1.57-1.31-2.37-1.95-.16-.13-.39-.21-.6-.21-1.47-.02-2.94-.05-4.41,0-1.15,.05-1.99-.31-2.4-1.44v-8.03Z"/><path class="cls-1" d="M46.44,25.8c-.1,6.25-1.88,11.26-5.45,15.62-.73,.89-1.81,1.04-2.64,.38-1.04-.82-1.24-1.87-.5-2.84,.94-1.22,1.82-2.47,2.5-3.85,.97-1.95,1.61-4.01,1.88-6.17,.64-5.09-.54-9.73-3.49-13.92-.36-.51-.77-.99-1.17-1.48-.8-.98-.66-2.05,.38-2.92,.83-.69,1.92-.54,2.68,.34,2.85,3.3,4.68,7.09,5.42,11.39,.23,1.34,.31,2.7,.39,3.45Z"/><path class="cls-1" d="M37.55,25.48c0,4.12-.97,7.02-2.86,9.61-.62,.85-1.71,1.05-2.54,.49-1.02-.7-1.31-1.78-.77-2.73,.53-.93,1.15-1.84,1.51-2.83,1.25-3.52,.76-6.83-1.38-9.9-.27-.38-.55-.79-.67-1.22-.25-.87,.33-1.83,1.24-2.26.78-.37,1.67-.13,2.25,.59,2.1,2.62,3.21,5.78,3.22,8.25Z"/></svg>'));
        display: block;
      }
    }
  }

  &__container.controller-secondary {
    .experience-video__controls__play {
      .isicon--video-play {
        background-image: escape-svg(url("data:image/svg+xml, <svg id='Livello_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52.54 53.55'><path d='M15.33,9.34c9.45,5.52,18.86,11.01,28.32,16.53-9.45,5.52-18.87,11.01-28.32,16.53V9.34Z'/></svg>"));
      }

      .isicon--video-pause {
        background-image: escape-svg(url("data:image/svg+xml, <svg id='Livello_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52.54 53.55'><path d='M34.46,17.3c0-1.55,.02-3.11-.03-4.66-.04-1.29-.86-2.11-2.14-2.24-1.73-.18-2.8,.87-2.8,2.79,0,9.06,0,18.11,0,27.17,0,.34,.03,.68,.11,1.01,.2,.94,.89,1.58,1.83,1.74,1.82,.31,3.02-.68,3.03-2.57,.02-4.56,0-9.13,0-13.69,0-3.18,0-6.36,0-9.54Z'/><path d='M22.92,12.11c-.25-.97-.89-1.53-1.88-1.69-1.75-.28-2.95,.72-2.96,2.5-.02,4.61,0,9.23,0,13.84s-.01,9.23,0,13.84c0,1.84,1.37,2.9,3.1,2.47,1.17-.29,1.85-1.28,1.85-2.71,0-9.08,0-18.17,0-27.25,0-.34-.04-.68-.12-1.01Z'/></svg>"));
      }
    }

    .video-volume {
      .isicon--volume-mute-fill__new {
        background-image: escape-svg(url('data:image/svg+xml, <svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.54 53.55"><defs><style>.cls-1{fill:#161615;}</style></defs><path class="cls-1" d="M25.88,12.96c-.66-.93-1.81-.98-2.76-.2-3.19,2.64-6.38,5.29-9.58,7.92-.16,.13-.41,.22-.63,.23-1.5,.02-2.99,.04-4.49,0-1.15-.03-1.96,.37-2.32,1.51v8.03c.41,1.13,1.25,1.48,2.4,1.44,1.47-.06,2.94-.02,4.41,0,.2,0,.44,.09,.6,.21,.8,.64,1.58,1.3,2.37,1.95,2.42,2.01,4.85,4.02,7.27,6.03,.43,.36,.91,.58,1.48,.5,1.03-.13,1.64-.89,1.64-2.05,0-7.18,0-14.35,0-21.53,0-.94,.04-1.89-.02-2.83-.02-.41-.14-.88-.38-1.2Z"/><polygon class="cls-1" points="44.55 21.77 42.55 19.78 37.52 24.81 32.51 19.8 30.58 21.73 35.59 26.74 30.55 31.78 32.55 33.77 37.58 28.74 42.58 33.74 44.51 31.81 39.51 26.8 44.55 21.77"/></svg>'));
      }

      .isicon--volume-up-fill__new {
        background-image: escape-svg(url("data:image/svg+xml, <svg id='Livello_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52.54 53.55'><path d='M6.11,22.41c.36-1.14,1.17-1.54,2.32-1.51,1.49,.04,2.99,.02,4.49,0,.21,0,.46-.09,.63-.23,3.2-2.63,6.39-5.28,9.58-7.92,.95-.78,2.1-.73,2.76,.2,.23,.33,.35,.79,.38,1.2,.05,.94,.02,1.89,.02,2.83,0,7.18,0,14.35,0,21.53,0,1.16-.61,1.92-1.64,2.05-.57,.07-1.05-.15-1.48-.5-2.42-2.01-4.85-4.02-7.27-6.03-.79-.65-1.57-1.31-2.37-1.95-.16-.13-.39-.21-.6-.21-1.47-.02-2.94-.05-4.41,0-1.15,.05-1.99-.31-2.4-1.44v-8.03Z'/><path d='M46.44,25.8c-.1,6.25-1.88,11.26-5.45,15.62-.73,.89-1.81,1.04-2.64,.38-1.04-.82-1.24-1.87-.5-2.84,.94-1.22,1.82-2.47,2.5-3.85,.97-1.95,1.61-4.01,1.88-6.17,.64-5.09-.54-9.73-3.49-13.92-.36-.51-.77-.99-1.17-1.48-.8-.98-.66-2.05,.38-2.92,.83-.69,1.92-.54,2.68,.34,2.85,3.3,4.68,7.09,5.42,11.39,.23,1.34,.31,2.7,.39,3.45Z'/><path d='M37.55,25.48c0,4.12-.97,7.02-2.86,9.61-.62,.85-1.71,1.05-2.54,.49-1.02-.7-1.31-1.78-.77-2.73,.53-.93,1.15-1.84,1.51-2.83,1.25-3.52,.76-6.83-1.38-9.9-.27-.38-.55-.79-.67-1.22-.25-.87,.33-1.83,1.24-2.26,.78-.37,1.67-.13,2.25,.59,2.1,2.62,3.21,5.78,3.22,8.25Z'/></svg>"));
      }
    }
  }
}
