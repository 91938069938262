.availability-msg {
  & > div {
    margin-bottom: $spacer;

    @include media-breakpoint-up(sm) {
      margin-bottom: rem-calc(-18);
      margin-top: rem-calc(38);
    }

    span {
      letter-spacing: revert;

      &::before {
        content: '';
        display: inline-block;
        width: rem-calc(6);
        height: rem-calc(6);
        margin-right: $spacer * 0.5;
        background-color: $beige;
        font-size: rem-calc(16) !important;
        margin-bottom: 2px;

        @include border-radius(50%);
      }
    }

    .not-available {
      &::before {
        background-color: $red;
      }
    }
  }
}
