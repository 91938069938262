.vto-widget,
.vto-oobexr-widget {
  &_container {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 300;
  }

  &_content {
    height: calc(100vh - 5rem);
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    background-color: #000;
    overflow: hidden;
    align-self: flex-end;

    .vto-product,
    .vto-oobexr-product {
      &_container {
        padding: 2rem 1.25rem;
        width: 100%;
        left: 0;
        bottom: 0;
        display: flex;
        position: sticky;
        align-items: center;
        z-index: 301;
        background-color: $white !important;

        &_title {
          color: $black !important;
        }
      }
    }

    #vto-widget,
    #vto-oobexr-widget {
      height: 100%;
      width: calc(((100vh - 5.375rem) - 5rem) * 1.78);
      position: relative;
      align-self: center;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }

    #vto-oobexr-widget {
      width: 100%;
    }
  }

  &_overlay {
    background: rgba(0, 0, 0, 0.7);
    z-index: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  &_button {
    &_close {
      position: fixed;
      top: 6.875rem;
      right: 0.875rem;
      color: $white !important;
      z-index: 300;
      padding: 1.25rem !important;
    }
  }
}

.vto-container,
.vto-oobexr-container {
  &_button {
    // &:hover {
    text-decoration: underline;
    // }
  }
}

.vto-oobexr-widget {
  &_button {
    &_close {
      color: $black !important;
      // top: auto;
      // padding: 1.563rem !important;
    }
  }
}
