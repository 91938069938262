@mixin tile__font-large {
  font-size: rem-calc(12);
  line-height: rem-calc(24);
  letter-spacing: 0;
}

.product {
  &--grid-tile {
    position: relative;

    .comparation-price {
      .value {
        @include media-breakpoint-down(sm) {
          font-size: rem-calc(10);
        }
      }
    }

    .image-container {
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: none;

        &.front {
          opacity: 1;
          z-index: 1;

          &.lazyloaded {
            animation: tilesImgFadeIn $transition-duration $transition-timing forwards;
          }
        }

        &.back {
          opacity: 0;
          z-index: 1;
        }

        &.base {
          position: relative;
          z-index: 0;
        }
      }

      &.with-back {
        &:hover {
          img {
            &.front {
              opacity: 0;
            }

            &.back {
              opacity: 1;
            }
          }
        }
      }
    }

    .tile-body {
      background-color: $white;
      padding: rem-calc(10) rem-calc(5);
      font-family: 'Jost', sans-serif;

      .name {
        flex: 1;
      }
    }

    .pdp-link {
      @include media-breakpoint-up(md) {
        @include tile__font-large;
      }

      .wishlist-icon {
        position: absolute;
        z-index: 1;
        right: rem-calc(8);
        top: rem-calc(5);
        align-self: flex-start;
        color: $primary;
      }

      .link {
        white-space: unset !important;
        font-weight: 600;
      }
    }

    .price {
      @include media-breakpoint-up(md) {
        @include tile__font-large;
      }
    }

    .color-swatches {
      position: relative;

      .swatches {
        margin-top: $spacer * 0.15;

        &__label {
          color: $gray-600;
          font-size: rem-calc(10);
          line-height: 0;

          &.empty {
            display: none;
          }
        }

        &__elements {
          position: absolute;
          top: calc(50% - 4px);
          left: 0;
          opacity: 0;
          visibility: hidden;
          line-height: rem-calc(8);
        }

        &__label,
        &__elements {
          transition: 250ms ease;
        }

        // @include hover-focus-active() {
        //   .swatches__label {
        //     opacity: 0;
        //     visibility: hidden;
        //   }

        //   .swatches__elements {
        //     opacity: 1;
        //     visibility: visible;

        //     a {
        //       text-decoration: none;
        //     }
        //   }
        // }

        .swatch {
          width: rem-calc(8);
          height: rem-calc(8);
          margin-right: rem-calc(2);

          // @include border-radius(50%);
        }

        &__color {
          width: rem-calc(9);
          height: rem-calc(9);
          display: inline-block;
          line-height: rem-calc(16);
          padding: 0;
          text-align: center;
          border: none;
          background-size: cover;
        }
      }
    }

    .price-original-container {
      text-align: right;

      &_label {
        color: $gray-500;
        font-size: rem-calc(10);
        white-space: normal;
      }
    }

    .wishlist-icon {
      display: block;
      position: absolute;
      top: rem-calc(15);
      right: rem-calc(15);
      z-index: 1;

      .isicon {
        color: $black;
        cursor: pointer;
        fill: $white;
      }

      &:hover .isicon {
        transform: scale(1.1);
      }

      &__text {
        text-decoration: underline;

        &::after {
          content: attr(data-add-text);
        }
      }

      &.state-selected .isicon {
        fill: $black;
        color: unset;
      }

      &.state-selected &__text {
        &::after {
          content: attr(data-remove-text);
        }
      }
    }
  }

  &-tile {
    .tile-body {
      .swatch-colors {
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        padding: 0;
        border: 1px solid transparent;
        text-align: center;
        border-radius: 50%;
        position: relative;

        &:hover {
          border-color: $black;
        }
      }

      .swatch {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid $black;
        background-size: cover;
      }

      .size-toggler {
        font-size: 13px;
        font-weight: 300;
        text-decoration: underline;
      }

      .image-badge__item {
        &.new-arrival {
          background-color: transparent;
          color: $black;
          margin-left: rem-calc(-6);
        }
      }

      .pdp-link {
        font-size: 16px;
        line-height: 1.1;
      }

      .price {
        font-size: 16px;
        margin-top: 0.3rem !important;
      }

      .price-original-container {
        display: none;
        pointer-events: none;
        opacity: 0;
      }
    }

    @import "_productTilesTall";
  }
}

@keyframes tilesImgFadeIn {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

.icon-betterdenim {
  fill: $betterdenim;
  // background-color: $white;
  // position: absolute;
  // bottom: $spacer * 0.5;
  // right: $spacer * 0.5;
  // z-index: 1;

  // @include border-radius(50%);

  svg {
    display: block;
  }

  &_text {
    color: $betterdenim;
    font-size: rem-calc(10);
    text-transform: uppercase;
    padding-left: rem-calc(5);
  }
}

@include media-breakpoint-down(md) {
  .tooltip {
    display: none;
  }
}
