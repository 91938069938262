@import "bootstrap/scss/functions";

// Buttons + Forms
$label-margin-bottom: $spacer * 0.5;
$input-btn-padding-y: rem-calc(18);
$input-btn-padding-x: $spacer * 0.75;
$input-btn-line-height: 1;

// Inputs
$input-padding-x: $spacer * 0.5;
$input-color: $primary;
$input-border-color: darken($gray-200, 5%);
$input-focus-border-color: $input-color;
$input-font-size: rem-calc(12);
$form-text-margin-top: 0;

// Buttons
// $btn-padding-y: rem-calc(13);
$btn-font-family: $headings-font-family;
$btn-font-size: rem-calc(13);

// Controls
$border-width: 1px;
$border-color: $gray-300;

$border-radius: 0;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;

// Custom Controls

$component-active-color: theme-color("primary");
$component-active-bg: $white;
$custom-control-indicator-bg-size: 100% 100%;
$custom-control-indicator-border-color: $gray-500 !default;

$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-checked-border-color: $custom-control-indicator-border-color;
$custom-control-indicator-focus-border-color: $custom-control-indicator-border-color;

$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='#{$custom-control-indicator-checked-color}' viewBox='0 0 20 20'><path d='M14.882 2L18 4.185 9.237 18 2 10.393l2.649-2.784 4.007 4.204L14.882 2z'/></svg>") !default;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='11' cy='11' r='5.5' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;
$custom-radio-indicator-icon-checked--medium: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='8' cy='8' r='5.5' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;

// Custom Select
$custom-select2-height: rem-calc(50);
$custom-select-padding-x: $input-padding-x;
$custom-select-padding-y: rem-calc(4);
$custom-select-bg-size: 12px 10px; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800;
$custom-select-indicator: url("data:image/svg+xml,<svg width='6' height='5' viewBox='0 0 6 5' xmlns='http://www.w3.org/2000/svg'><path d='M0 1.63877L0.571528 1L3 3.17284L5.42847 1L6 1.63877L3 4.32298L0 1.63877Z' fill='#{$custom-select-indicator-color}'/></svg>");
$custom-select-background: escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)
$enable-validation-icons: false;

// Forms
$form-group-margin-bottom: rem-calc(15);
$custom-select-line-height: 1.5;

// Navs

$nav-link-padding-y: rem-calc(18);

$nav-tabs-border-width: 2px; // in px as decorative
$nav-tabs-link-bg: $gray-200;
$nav-tabs-link-hover-border-color: $nav-tabs-link-bg;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $white;

// Counters
$counter-size: rem-calc(20);
$counter-font-size: rem-calc(10);
