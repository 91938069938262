@keyframes scaleIn {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@mixin animation($name, $animation-duration: $transition-duration, $animation-timing: $transition-timing) {
  animation: #{$name} $animation-duration $animation-timing forwards;
}
