.slot {
  &--product-arrow-carousel {
    &__arrows {
      .btn {
        &:first-child {
          border-right: transparent;
        }
      }
    }
  }
}
