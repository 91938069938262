// All of this !important is needed to override the cookiebot inline styles
#CybotCookiebotDialog {
  bottom: 0;
  top: unset !important;
  transform: translate(-50%) !important;
  width: 100% !important;
  border-radius: 0 !important;

  &,
  & .CybotCookiebotDialogContentWrapper {
    max-width: none !important;
  }

  #CybotCookiebotDialogHeader {
    display: none !important;
  }

  #CybotCookiebotDialogTabContent {
    margin-left: 0 !important;
  }

  & * {
    font-size: rem-calc(9) !important;
    line-height: 1.2rem !important;

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(10) !important;
    }
  }
}
