.counter {
  position: absolute;
  left: 50%;
  top: 50%;
  background: $primary;
  border-radius: 50%;
  text-align: center;
  width: $counter-size;
  height: $counter-size;
  line-height: #{$counter-size * 1.1};
  font-size: $counter-font-size;
  font-weight: $font-weight-bold;
  letter-spacing: rem-calc(0.42);
  color: $white;

  &.animate {
    @include animation('scaleIn', 500ms);
  }
}
