.hero-slider {
  &__indicators {
    @include media-breakpoint-down(md) {
      bottom: 30px !important;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    @include media-breakpoint-up(md) {
      width: 5%;
    }

    &-icon {
      width: rem-calc(40);
      height: rem-calc(60);
    }
  }

  .carousel-control-prev {
    &-icon {
      transform: rotate(180deg);
      background-image: url(escape-svg("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='13' viewBox='0 0 10 13' fill='white'%3E%3Cpath fill-rule='evenodd' stroke='white' stroke-linecap='square' stroke-width='1.5' d='M3 1.5l5 5m0 0l-5 5'%2F%3E%3C%2Fsvg%3E"));
    }
  }

  .carousel-control-next {
    &-icon {
      background-image: url(escape-svg("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='13' viewBox='0 0 10 13' fill='white'%3E%3Cpath fill-rule='evenodd' stroke='white' stroke-linecap='square' stroke-width='1.5' d='M3 1.5l5 5m0 0l-5 5'%2F%3E%3C%2Fsvg%3E"));
    }
  }

  .carousel-indicators {
    li {
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #fff;
      background-clip: padding-box;
      opacity: 1;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      border-width: 2px;

      &.active {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: #fff;
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }
}

.js-indicators {
  margin: 0 !important;
  z-index: 2 !important;
  bottom: rem-calc(17) !important;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.js-indicator {
  border: none !important;
}
