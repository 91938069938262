:root {
  font-size: $global-font-size;
}

html {
  font-size: 1rem;

  &.disableScroll {
    &,
    body {
      overflow: hidden;
    }
  }
}

p {
  font-size: rem-calc(14);
  line-height: rem-calc(26);

  .link,
  .btn-link {
    text-decoration: underline;
  }
}

.small {
  @include small;
}

.smaller {
  @include smaller;
}

.strike-through {
  text-decoration: line-through;
}

.visuallyhidden {
  @include visuallyhidden;
}

@include responsive-font-map(h1, $font-h1-maps, $grid-breakpoints);
@include responsive-font-map(h2, $font-h2-maps, $grid-breakpoints);
@include responsive-font-map(h3, $font-h3-maps, $grid-breakpoints);

.section-title {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      font-family: $headings-font-family;
      font-weight: $headings-font-weight;
      font-size: rem-calc(18);
      line-height: rem-calc(28);
      letter-spacing: 0;
    }
  }
}

.page-title {
  font-size: rem-calc(22);
  line-height: rem-calc(32);
}

@each $name, $value in $grays {
  .text-gray-#{$name} {
    color: $value;
  }
}

.color-swatches .swatches a {
  pointer-events: none;
}

.text-md-nowrap {
  @include media-breakpoint-up(md) {
    white-space: nowrap !important;
  }
}
