.component_hub {
  position: relative;
  z-index: 0;

  #scrollButton {
    color: white;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22Livello_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2052.54%2053.55%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bisolation%3Aisolate%3Bopacity%3A.75%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22arrow_left_desk%22%3E%3Ccircle%20id%3D%22Ellisse_1%22%20class%3D%22cls-1%22%20cx%3D%2226.27%22%20cy%3D%2226.77%22%20r%3D%2224%22%2F%3E%3Cg%20id%3D%22Tracciato_123%22%3E%3Cpolygon%20points%3D%2226.27%2032.07%2018.3%2022.78%2019.82%2021.48%2026.27%2029%2032.73%2021.48%2034.24%2022.78%2026.27%2032.07%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    position: absolute;
    width: 42px;
    height: 42px;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      bottom: 20px;
      width: 42px;
      height: 42px;
    }
  }

  .content-light {
    color: $white;
  }

  .hub-content__wrapper {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  picture {
    width: 100%;
  }

  .btn-link {
    text-decoration: none;
    border-bottom: 1px solid $black;

    &.small-text {
      font-size: rem-calc(13);
    }

    &.medium-text {
      font-size: rem-calc(16);
    }

    &.large-text {
      font-size: rem-calc(18);
    }

    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: unset;
    }

    @include media-breakpoint-up(lg) {
      border-bottom: 2px solid $black;
    }

    &--light {
      color: $white;

      @include media-breakpoint-up(lg) {
        border-bottom: 2px solid $white;
      }
    }
  }

  .full-height {
    overflow: hidden;
    height: 0;
    padding-top: 50%;

    @include media-breakpoint-up(xl) {
      padding-top: 45%;
    }
  }

  .content-overlay {
    z-index: 1;

    @include media-breakpoint-up(lg) {
      top: 15%;
      left: 50%;
      transform: translateX(-25px);
    }

    &__title {
      z-index: 1;
      top: 15%;
      line-height: 1.05;

      @include media-breakpoint-up(md) {
        top: 25%;
      }
    }

    &__sub,
    &__subMobile {
      .copy {
        line-height: 1.2;

        &.small-text {
          font-size: rem-calc(10);

          @include media-breakpoint-up(lg) {
            font-size: rem-calc(14);
          }

          @include media-breakpoint-up(xl) {
            font-size: rem-calc(15);
          }
        }

        &.medium-text {
          font-size: rem-calc(13);

          @include media-breakpoint-up(lg) {
            font-size: rem-calc(15);
          }

          @include media-breakpoint-up(xl) {
            font-size: rem-calc(18);
          }
        }

        &.large-text {
          font-size: rem-calc(15);

          @include media-breakpoint-up(lg) {
            font-size: rem-calc(18);
          }

          @include media-breakpoint-up(xl) {
            font-size: rem-calc(20);
          }
        }
      }
    }
  }
}
