.draggable-horizontal-slider {
  width: 100%;

  &__content {
    > div {
      &:first-child {
        @include scrollable();
      }
    }
  }

  &__control {
    z-index: -1;
    position: absolute;
    width: 10%;
    height: 100%;
    top: 0;

    &:hover {
      cursor: move;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  .disable-pointer-events {
    pointer-events: none;
  }
}
