.recently-viewed {
  .slot--product-carousel {
    display: none;
  }

  .product {
    &--grid-tile {
      @include suggestions(xs);

      @include media-breakpoint-up(sm) {
        @include regular();
      }

      @include media-breakpoint-up(md) {
        @include tiny(md);
      }
    }
  }
}
