@import "~common/utils/mixinsAsync";

// Color system

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9e9e9;
$gray-300: #dee2e6;
$gray-400: #b3b3b3;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #4c4c4c;
$gray-800: #343a40;
$gray-900: #212529;
$gray-1000: #1e1e1e;
$black: #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
    "1000": $gray-1000
  ),
  $grays
);

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$violet: #ba6fcd;
$pink: #e83e8c;
$fucsia: #fa347f;
$red: #c4184e;
$orange: #fd7e14;
$beige: #d1951f;
$yellow: #ffc107;
$green: #16b75d;
$teal: #20c997;
$cyan: #17a2b8;
$betterdenim: #7f7f7f;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "violet": $violet,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "beige": $beige,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-lighter": $gray-400,
    "gray-light": $gray-700,
    "gray-dark": $gray-800,
    "light-black": $gray-1000,
    "betterdenim": $betterdenim
  ),
  $colors
);

$primary: $black;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $white;
$dark: $black;
$gray-lighter: $gray-400;
$gray-light: $gray-700;
$light-black: $gray-1000;
$sale: $red;
$betterdenim: $betterdenim;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "gray-lighter": $gray-lighter,
  "gray-light": $gray-light,
  "light-black":$light-black,
  "sale":		  $sale
);
