@mixin widget_cta {
  font-size: 1.14286rem;
  font-weight: 700;
  font-family: Jost, Helvetica Neue, Helvetica, Arial, sans-serif;
  padding: 0 0 5px !important;
  border-bottom: 2px solid black;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: unset;
  }
}

@mixin widget_body {
  font-size: 1rem;
  line-height: 1.4;
}

@mixin widget_bold_title {
  font-weight: 500;
}

@mixin widget_light_title {
  font-weight: 300;
}
