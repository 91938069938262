.modal {
  &-content {
    &.text-white {
      button,
      .btn {
        color: $white;
      }
    }

    #consent-tracking & {
      background-color: $gray-200;

      p {
        margin-bottom: 0;

        @include small;
      }

      .btn {
        @include hover-focus-active() {
          text-decoration: none;
        }
      }
    }
  }

  &-dialog {
    #newsletter-modal &,
    #consent-tracking &,
    #loyalty-modal & {
      width: 100%;
      max-width: 100%;
    }
  }

  &-header {
    #newsletter-modal & {
      h3 {
        font-weight: $font-weight-normal;
      }
    }
  }

  .input-group {
    .form-control {
      height: rem-calc(50);
    }

    .btn {
      &-editorial {
        color: $primary;
        width: auto;
        min-width: auto;
        background-color: $white;

        @include button-animation($white, $white, $transition-duration, $btn-transition-timing);

        @include hover-focus-active {
          @include button-animation($white, $white, $transition-duration, $btn-transition-timing);
        }
      }
    }
  }

  &#newsletter-modal {
    top: initial;
    right: 0;
    bottom: 0;
    height: auto;
    min-width: auto;
    left: auto;
    padding: 0 !important;
    z-index: 10;

    @include media-breakpoint-up(sm) {
      width: rem-calc(533);
    }
  }

  &#consent-tracking {
    top: initial;
    bottom: 0;
    height: auto;
  }
}
