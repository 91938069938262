.login-page {
  .font-weight-bold {
    font-weight: 500 !important;
  }

  &__section--sign-up {
    @include media-breakpoint-up(md) {
      border-right: 1px solid #dcdcdc;
    }
  }

  label {
    letter-spacing: 0;
  }
}
