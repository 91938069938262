.suggestions {
  &-wrapper {
    @include media-breakpoint-down(sm) {
      overflow-x: hidden;
      overflow-y: auto;
      flex: 1 1;
    }

    @include media-breakpoint-up(lg) {
      .name.col-lg-6 {
        max-width: 100%;
      }
    }
  }

  &__product {
    .media {
      margin-top: map-get($grid-gutter-widths, xs);
    }

    img {
      width: rem-calc(65);
      align-self: flex-start;

      @include media-breakpoint-up(md) {
        width: rem-calc(79);
      }
    }

    .price {
      @include media-breakpoint-down(md) {
        @include small;
      }
    }

    &-title {
      font-weight: $font-weight-semibold;
    }
  }

  &__list,
  &__product {
    overflow: auto;

    .price {
      font-size: rem-calc(10);

      @include media-breakpoint-only(sm) {
        margin-bottom: $spacer;
      }

      @include media-breakpoint-up(lg) {
        font-size: rem-calc(12);
      }

      &-title {
        font-size: rem-calc(20);

        @include media-breakpoint-up(lg) {
          font-size: rem-calc(22);
        }

        @include media-breakpoint-up(xl) {
          font-size: rem-calc(24);
        }
      }

      .pdp-link {
        .link {
          font-weight: bold;
          font-size: rem-calc(11);

          @include media-breakpoint-up(lg) {
            font-size: rem-calc(12);
          }

          @include media-breakpoint-up(xl) {
            font-size: rem-calc(15);
          }
        }
      }
    }

    .search {
      &-options {
        &_radio {
          .custom-control-input {
            &:checked {
              & ~ label {
                font-weight: 500;
                color: $black;
                border-right: 2px solid $black;

                &::after {
                  content: "";
                  background: black;
                  position: absolute;
                  height: 2px;
                  width: 50%;
                  left: 0;
                  bottom: 0;
                  right: 8px;
                  margin: 0 auto;
                }
              }
            }
          }

          label {
            font-size: rem-calc(21);
            color: $gray-600;
            padding-right: rem-calc(8);
            border-right: 2px solid $gray-600;
          }

          .custom-radio {
            label {
              font-family: $headings-font-family;
              font-weight: $font-weight-semibold;
              font-size: rem-calc(16);
            }

            &:last-child {
              label {
                border-right: none;
              }
            }
          }
        }
      }
    }

    &-recommendations {
      .searchbarCarousel-callout {
        font-weight: 500;
        font-size: rem-calc(20);

        @include media-breakpoint-up(lg) {
          font-size: rem-calc(22);
        }

        @include media-breakpoint-up(xl) {
          font-size: rem-calc(24);
        }
      }

      .pdp-link {
        .link {
          font-weight: bold;
          font-size: rem-calc(11);

          @include media-breakpoint-up(lg) {
            font-size: rem-calc(12);
          }

          @include media-breakpoint-up(xl) {
            font-size: rem-calc(15);
          }
        }
      }
    }
  }

  &__list {
    .search-suggestions {
      &_manual {
        padding-right: rem-calc(60);
      }

      &_manual,
      &-new_manual {
        h3 {
          font-size: rem-calc(20);
          font-weight: $font-weight-semibold;

          @include media-breakpoint-up(lg) {
            font-size: rem-calc(22);
          }

          @include media-breakpoint-up(xl) {
            font-size: rem-calc(24);
          }
        }
      }
    }
  }

  &__search-results {
    font-size: rem-calc(15);
  }
}
