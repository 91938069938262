@import "~common/bootstrap/mixins/responsive-gutters";

.back-to-top,
.show-more {
  &__container {
    margin-bottom: -1px;
  }

  &__border {
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
  }
}

footer {
  &.footer {
    background-color: $white;
  }
}

.prefooter {
  p {
    line-height: rem-calc(26);
  }

  .separator {
    position: relative;

    @include media-breakpoint-down(xs) {
      &::after {
        display: block;
        content: '';
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        margin-left: map-get($grid-gutter-widths, xs) / (-2);
        margin-right: map-get($grid-gutter-widths, xs) / (-2);
        border-bottom: $default-border;
      }
    }

    @include media-breakpoint-up(sm) {
      border-bottom: 0;
      border-right: $default-border;
    }
  }
}

.footer {
  &.text-white {
    a {
      color: $white;

      @include hover-focus-active {
        color: $white;
      }
    }
  }

  &__card {
    @include media-breakpoint-down(sm) {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .card {
      &-header {
        .btn {
          font-family: $font-family-sans-serif;
          font-weight: $font-weight-bold;

          @include media-breakpoint-down(xs) {
            padding-right: map-get($grid-gutter-widths, xs) / 2 !important;
            padding-left: map-get($grid-gutter-widths, xs) / 2 !important;
          }

          @include media-breakpoint-only(sm) {
            padding-right: map-get($grid-gutter-widths, sm) / 2 !important;
            padding-left: map-get($grid-gutter-widths, sm) / 2 !important;
          }

          &::after {
            right: map-get($grid-gutter-widths, xs) / 2 !important;

            @include media-breakpoint-only(sm) {
              right: map-get($grid-gutter-widths, sm) / 2 !important;
            }
          }
        }
      }

      &-body {
        @include media-breakpoint-down(xs) {
          padding-right: map-get($grid-gutter-widths, xs) / 2 !important;
          padding-left: map-get($grid-gutter-widths, xs) / 2 !important;
        }

        @include media-breakpoint-only(sm) {
          padding-right: map-get($grid-gutter-widths, sm) / 2 !important;
          padding-left: map-get($grid-gutter-widths, sm) / 2 !important;
        }
      }
    }

    // Button to modify cookies on legal page
    .modify-cookies {
      color: $white;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .accordion {
    .collapse {
      @include media-breakpoint-up(lg) {
        display: block !important;
      }
    }

    .btn {
      width: 100%;
      border: 1px solid transparent;

      @include media-breakpoint-up(lg) {
        pointer-events: none;
        cursor: default;
      }

      &-link {
        margin-bottom: 0;

        @include hover-focus-active() {
          text-decoration: none;
        }
      }

      @include hover-focus-active() {
        border: 1px solid rgba($white, 0.35);
        box-shadow: none !important;
      }
    }

    .btn,
    li {
      padding-top: $spacer * 0.75;

      @include media-breakpoint-up(lg) {
        padding-top: $spacer * 0.5;
      }

      &:first-child {
        padding-top: $spacer * 0.5;
      }

      &:last-child {
        padding-bottom: $spacer * 0.5;
      }
    }

    li {
      &:last-child {
        @include media-breakpoint-down(md) {
          padding-bottom: $spacer;
        }
      }
    }
  }
}

.postfooter {
  &.text-white {
    a {
      color: $white;

      @include hover-focus-active {
        color: $white;
      }
    }
  }

  .container-list-social {
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1 1 0%;
    }
  }

  &__list-group-item {
    white-space: nowrap;
    flex: 0 0 auto;
    width: auto;

    @include responsive-gutters($grid-gutter-widths, $grid-breakpoints, padding);

    @include media-breakpoint-up(lg) {
      margin-right: $spacer * 0.75;
    }

    &:first-child {
      border-top: 0;
    }

    a {
      @include media-breakpoint-down(md) {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }

    span {
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
  }

  .social {
    .isicon {
      display: block;
      fill: $white;
    }
  }

  .container-text {
    @include media-breakpoint-up(lg) {
      padding-top: $spacer * 0.75;
      padding-bottom: $spacer * 0.75;
    }

    p {
      @include media-breakpoint-down(md) {
        font-size: rem-calc(10);
        line-height: rem-calc(19);
      }
    }
  }
}

.footer,
.postfooter {
  &__border {
    @include media-breakpoint-down(md) {
      border: 0 !important;
    }

    // Modal to modify cookies on legal page
    .cookies-modify-modal.modal {
      color: $black;
      bottom: 0 !important;
      top: auto !important;
      max-width: min-content;
      min-width: 100%;
      height: auto;

      @include media-breakpoint-up(md) {
        left: rem-calc(20) !important;
        min-width: max-content;
      }

      .cookies-consent {
        p {
          margin-bottom: rem-calc(20);
        }
      }
    }
  }

  &__card,
  &__list-group-item {
    background-color: $primary;
    border-color: $light-black;

    @include media-breakpoint-up(lg) {
      border: 0;
    }
  }
}
