@import "../experience/components/mixins";

.featured-product {
  position: relative;
  z-index: 0;

  &__slider {
    &--control {
      bottom: 0;
      right: 20px;
    }

    &--prev,
    &--next {
      width: auto;

      &-icon {
        display: inline-block;

        .isicon {
          width: 25px !important;
          height: 25px !important;
          line-height: 25px !important;

          @include media-breakpoint-up(lg) {
            width: 30px !important;
            height: 30px !important;
            line-height: 30px !important;
          }

          @include media-breakpoint-up(xl) {
            width: 35px !important;
            height: 35px !important;
            line-height: 35px !important;
          }
        }
      }
    }

    .carousel-indicators {
      position: absolute;
      bottom: -15px;

      button {
        display: inline-block;
        height: 4px;
        margin: 0 3px;
        cursor: pointer;
        padding: 0;
        background-color: $gray-600;

        &:focus-visible,
        &:focus {
          outline: none;
        }
      }

      button.active {
        background-color: $black;
      }
    }

    .carousel-inner {
      picture {
        img {
          object-fit: cover;
        }

        .r1x1 {
          aspect-ratio: 1/1;
        }

        .r4x5 {
          aspect-ratio: 4/5;
        }
      }
    }
  }

  &__content {
    &--featured {
      font-size: rem-calc(16);

      @include media-breakpoint-up(lg) {
        font-size: rem-calc(24);
      }
    }

    &--title {
      @include widget_bold_title;

      font-size: rem-calc(70);

      @include media-breakpoint-down(md) {
        font-size: rem-calc(50);
      }
    }

    &--copy {
      @include widget_body;
    }

    &--CTA {
      a {
        @include widget_cta;
      }
    }
  }
}
