@import "../experience/components/mixins";

.component_magazine {
  z-index: 1;

  @include media-breakpoint-up(lg) {
    position: relative;
    overflow-y: hidden;
    height: 0;
    padding-top: 50%;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 55%;
  }

  &-wrapper {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    color: $black;
    line-height: 1.1;
    font-weight: $font-weight-semibold;
  }

  .subtitle {
    color: $black;
    font-weight: $font-weight-semibold;
  }

  .copy {
    color: $black;
    line-height: 1.4;

    &.small-text {
      font-size: rem-calc(13);

      // @include media-breakpoint-up(lg) {
      //   font-size: rem-calc(13);
      // }

      // @include media-breakpoint-up(xl) {
      //   font-size: rem-calc(20);
      // }
    }

    &.medium-text {
      font-size: rem-calc(16);

      @include media-breakpoint-up(lg) {
        font-size: rem-calc(14);
      }

      @include media-breakpoint-up(xl) {
        font-size: rem-calc(24);
      }
    }

    &.large-text {
      font-size: rem-calc(18);

      @include media-breakpoint-up(lg) {
        font-size: rem-calc(16);
      }

      @include media-breakpoint-up(xl) {
        font-size: rem-calc(28);
      }
    }
  }

  .photo-magazine {
    @include media-breakpoint-up(lg) {
      img {
        position: absolute;
        top: 0;
        height: 100%;
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        aspect-ratio: 4/5;
      }
    }
  }

  .cta-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 1;
    // padding-bottom: 0;

    .btn-link {
      @include widget_cta;

      margin: 30px 20px;

      span {
        &:focus-visible {
          outline: none;
          box-shadow: none;
        }
      }

      &--primary {
        color: $black;
      }

      &--secondary {
        color: $white;
        border-bottom: 2px solid $white !important;
        text-decoration: none;
      }
    }
  }
}
